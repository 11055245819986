import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton, CAlert } from "@coreui/react";
import { toast } from "react-toastify";
import CenterSpinner from "../../../../../../general/Loadings/CenterSpinner.js";
import {
     API_SALESFORCE_RULE,
     API_SALESFORCE_SELECT_FIELDS,
     TYPE_SHOW_UNSAVE_CHANGE,
     WRITE_RULE_SECTION,
     API_SALESFORCE_RECREATE_CONNECTION,
     SALESFORCE_OBJECT_RELATION,
     SALESFORCE_LIST_FIELD_SETUP,
} from "../../../../../../../constants/index.js";
import { setRuleHaveEditting } from "../../../../../../../actions/common.js";
import { callSalesforceApi } from "../../../../../../../apiCaller.js";
import { SFSetProgressHistoricalSync } from "../../../../../../../actions/external.js";

export const ObjectDetailContext = React.createContext({});

const DataSourceConnection = React.lazy(() =>
     import(`./steps/DataSourceConnection.js`)
);
const SystemFields = React.lazy(() => import(`./steps/SystemFields.js`));
const PreviewObject = React.lazy(() => import(`./steps/PreviewObject.js`));
const SetUpFields = React.lazy(() => import(`./steps/SetUpFields.js`));
const PipelineFields = React.lazy(() => import(`./steps/PipelineFields.js`));
const ValueFields = React.lazy(() => import(`./steps/ValueFieldsV2.js`));
const RelationshipFields = React.lazy(() =>
     import(`./steps/RelationshipFields.js`)
);
const TargetFields = React.lazy(() => import(`./steps/TargetFields.js`));
const OtherFields = React.lazy(() => import(`./steps/OtherFields.js`));
const CustomFields = React.lazy(() => import(`./steps/CustomFields.js`));
// const Relationships = React.lazy(() => import(`./steps/Relationships.js`));
const NameDescription = React.lazy(() => import(`./steps/NameDescription.js`));
const HistoricalSync = React.lazy(() => import(`./steps/HistoricalSync.js`));

const ObjectImportDetail = ({ id }) => {
     const dispatch = useDispatch();
     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState({});
     const [currentData, setCurrentData] = useState({});
     const [initStepsData, setInitStepsData] = useState({});
     const [listObjectsField, setListObjectsField] = useState([]);
     const [listFields, setListFields] = useState([]);
     const [isLoadingGetNewField, setIsLoadingGetNewField] = useState(false);
     const [stepsMappingField, setStepsMappingField] = useState({});
     const [ruleSetup, setRuleSetup] = useState(false);
     const [ruleSetupConnectionId, setRuleSetupConnectionId] = useState("");
     const [logError, setLogError] = useState(false);
     const [setupRequired, setSetupRequired] = useState(false);
     const [selectExistingState, setSelectExistingState] = useState({});
     const [stuckStepIndex, setStuckStepIndex] = useState();
     const statusRecreate = useSelector(
          (state) => state.subscriber.accountExternalData.status
     );

     const setSelectExistingFor = (id, value) => {
          setSelectExistingState((prev) => ({
               ...prev,
               [id]: value,
          }));
     };

     const fetchDataSelectFields = (object, getNew = false, isNewRelations = false) => {
          let endPoint = `${API_SALESFORCE_SELECT_FIELDS}?ruleId=${id}`;
          if (getNew) {
               if (isNewRelations) {
                    endPoint = `${endPoint}&getNew=true&isNewRelations=true`;
               } else {
                    endPoint = `${endPoint}&getNew=true`;
               }
          }

          return callSalesforceApi(endPoint, "GET").then((response) => {
               if (response && response.status === 200) {
                    const { data, newFields, deletedFields } = response.data;

                    if (data) {
                         const addDefaultField = (fields) => {
                              fields.unshift({
                                   value: "",
                                   label: "Select a field",
                                   type: "default",
                                   section: "",
                              });
                         };

                         const addGetNewFields = (fields) => {
                              if (!fields.some((option) => option.value === "getNewFields")) {
                                   fields.push({
                                        name: "getNewFields",
                                        value: "getNewFields",
                                        newFields: true,
                                        type: "default",
                                        section: "",
                                        label: (
                                             <CButton
                                                  className="option-create-categories"
                                                  color="link"
                                                  onClick={() => handleGetNewField(false)}
                                             >
                                                  <i className="fal fa-plus"></i>
                                                  <span className="pl-1 text-primary">Get new fields</span>
                                             </CButton>
                                        ),
                                   });
                              }
                         };

                         // Generate select field options
                         const selectFieldsOptions = data.map((field) => ({
                              value: field.name,
                              label: field.name,
                              type: field.type,
                              custom: field.custom,
                              labelField: field.label,
                              section: WRITE_RULE_SECTION[field.type] === 'default' ? '' : WRITE_RULE_SECTION[field.type] ?? 'Textarea',
                         }));

                         const dataOptions = data
                              .map((field) => ({
                                   ...field,
                                   labelField: field.label,
                              }))
                              .sort((a, b) => a.labelField.localeCompare(b.labelField));

                         function filterDupplicateData(records) {
                              const labelCount = records.reduce((acc, label) => {
                                   acc[label.labelField] = (acc[label.labelField] || 0) + 1;
                                   return acc;
                              }, {});

                              return records.map((_item, index) => {
                                   const fieldName = records[index];

                                   if (_item.labelField && labelCount[_item.labelField] > 1) {
                                        return {
                                             ...fieldName,
                                             labelField: `${fieldName.labelField} (${fieldName.value || fieldName.name
                                                  })`,
                                        };
                                   }

                                   return fieldName;
                              });
                         }

                         // Add default option to both lists
                         addDefaultField(dataOptions);
                         addDefaultField(selectFieldsOptions);

                         // Add "getNewFields" option to both lists
                         addGetNewFields(dataOptions);
                         addGetNewFields(selectFieldsOptions);

                         // Update state
                         setListFields(filterDupplicateData(dataOptions));
                         setListObjectsField(filterDupplicateData(selectFieldsOptions));

                         if (getNew && (newFields.length > 0 || deletedFields.length > 0)) {
                              handleHasChange();
                              setCurrentData((prev) => {
                                   const deletedNames = new Set(deletedFields.map(field => field.name));

                                   const uniqueNewFields = newFields.filter((newField) => 
                                        !prev.relationshipFieldsMapping.some(existingField => 
                                             existingField.name === newField.name && existingField.value === newField.value
                                        )
                                   );

                                   const filteredRelationshipFields = prev.relationshipFieldsMapping.filter(
                                        (field) => !deletedNames.has(field.name)
                                   );
                           
                                   return {
                                        ...prev,
                                        relationshipFieldsMapping: [
                                             ...filteredRelationshipFields,
                                             ...uniqueNewFields
                                        ]
                                   };
                              });
                         }
                    }
               } else {
                    toast.error("Get object info failed!");
               }
          });
     };

     const customFilter = useCallback((option, searchText) => {
          if (
               option.data.labelField
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()) ||
               option.data.type === "default"
          ) {
               return true;
          }

          return false;
     }, []);

     const handleGetNewField = (isNewRelations) => {
          setIsLoadingGetNewField(true);

          const object = stepsData.object;
          fetchDataSelectFields(object, true, isNewRelations)
               .then(() => {})
               .finally(() => setIsLoadingGetNewField(false));
     };

     const handleRuleLogError = useCallback(() => {
          if (!id) return;

          return callSalesforceApi(
               `${API_SALESFORCE_RULE}/log-error/${id}`,
               "POST"
          ).then((response) => {
               if (response && response.status === 200) {
                    setLogError(false);
               }
          });
     }, [id]);

     const fetchMappingField = () => {
          if (!id) return;

          return callSalesforceApi(
               `${API_SALESFORCE_RULE}/get-mapping-field/${id}`,
               "GET"
          ).then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;

                    if (data) {
                         setStepsMappingField(data);
                    }
               } else {
                    if (response && response.status === 400 && response.data.isSetupRule) {
                         setRuleSetup(response.data.isSetupRule);
                         setRuleSetupConnectionId(response.data.connectionId);
                    } else {
                         toast.error("Get data failed!");
                    }
               }
          });
     };

     const fetchData = () => {
          if (!id) return;

          setIsLoading(true);
          fetchMappingField();
          callSalesforceApi(`${API_SALESFORCE_RULE}/${id}`, "GET").then(
               (response) => {
                    if (response && response.status === 200) {
                         const { data } = response.data;
                         const rule = data;

                         if (data) {
                              dispatch(SFSetProgressHistoricalSync(data.jobActive));
                              setLogError(data.isErrorSupported);
                              setStepsData(data);
                              setCurrentData(data);
                              setInitStepsData(data);
                              setRuleSetup(false);
                              setRuleSetupConnectionId("");
                              const object = rule.object;
                              fetchDataSelectFields(object).finally(() => setIsLoading(false));
                         }
                    } else {
                         if (
                              response &&
                              response.status === 400 &&
                              response.data.isSetupRule
                         ) {
                              setRuleSetup(response.data.isSetupRule);
                              setRuleSetupConnectionId(response.data.connectionId);
                         } else {
                              toast.error("Get data failed!");
                         }
                    }
               }
          );
     };

     useEffect(fetchData, []); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchDataGetNew = () => {
          if (!id) return;

          callSalesforceApi(`${API_SALESFORCE_RULE}/${id}`, "GET").then(
               (response) => {
                    if (response && response.status === 200) {
                         const { data } = response.data;

                         if (data) {
                              setStepsData(data);
                              setInitStepsData(data);
                              setCurrentData(data);
                         }
                    }
               }
          );
     };

     const specialCondition = useMemo(
          () => ["empty", "exav", "notSelected", "isSelected"],
          []
     );

     useEffect(() => {
          if (!stepsData) return;

          const allFields = [
               { key: "systemFieldsMapping", data: stepsData.systemFieldsMapping || [] },
               {
                    key: "pipelineFieldsMapping",
                    data: stepsData.pipelineFieldsMapping || [],
               },
               { key: "valueFieldsMapping", data: stepsData.valueFieldsMapping || [] },
               {
                    key: "relationshipFieldsMapping",
                    data: stepsData.relationshipFieldsMapping || [],
               },
               { key: "targetFieldsMapping", data: stepsData.targetFieldsMapping || [] },
               { key: "otherFieldsMapping", data: stepsData.otherFieldsMapping || [] },
               { key: "setUpFieldsMapping", data: stepsData.setUpFieldsMapping || [] },
          ].flatMap(({ key, data }) =>
               data.map((item) => ({ ...item, source: key }))
          );

          const setup = SALESFORCE_LIST_FIELD_SETUP.find(
               (item) => item.object === stepsData.object
          );

          let isRequired = true;
          let missingFields = [];

          if (setup) {
               isRequired = setup.field.every((fieldName) => {
                    const fieldInfo = allFields.find((item) => item.name === fieldName);
                    const fieldValue = fieldInfo?.isWriteRule
                         ? fieldInfo.writeRuleValue ||
                         specialCondition.includes(fieldInfo.writeRuleCondition)
                         : fieldInfo?.value;

                    const validateDependentField = (mainField, dependentField) => {
                         const mainFieldInfo = allFields.find(
                              (item) => item.name === mainField
                         );
                         const dependentFieldInfo = allFields.find(
                              (item) => item.name === dependentField
                         );

                         const mainValue = mainFieldInfo?.isWriteRule
                              ? mainFieldInfo.writeRuleValue ||
                              specialCondition.includes(mainFieldInfo.writeRuleCondition)
                              : mainFieldInfo?.value;

                         const dependentValue = dependentFieldInfo?.value;
                         const isAuto = dependentFieldInfo?.isAutomatically === true;

                         if (mainValue && !dependentValue && !isAuto) {
                              missingFields.push({
                                   fieldName: dependentField,
                                   source: dependentFieldInfo?.source || "Unknown",
                                   value: dependentValue,
                              });
                         }
                    };

                    validateDependentField("IsCustomer", "IsCustomerDate");
                    validateDependentField("IsLostCustomer", "IsLostCustomerDate");

                    if (!fieldValue) {
                         missingFields.push({
                              fieldName,
                              source: fieldInfo?.source || "Unknown",
                              value: fieldValue,
                         });
                    }

                    return !!fieldValue;
               });
          }

          const stepWithMissingFields = componentsWithData.find(({ component }) =>
               missingFields.some(
                    ({ source }) =>
                         mappingComponents.find((m) => m.component === component)?.key ===
                         source
               )
          );

          let stuckIndex = -1;
          if (stepWithMissingFields) {
               stuckIndex = componentsWithData.findIndex(
                    (c) => c.component === stepWithMissingFields.component
               );
               componentsWithData.forEach(
                    (c, index) => (c.isDisabled = index > stuckIndex)
               );
          }

          const firstDisabledStepIndex = componentsWithData.findIndex(
               (c) => c.isDisabled
          );
          const newStuckStepIndex =
               firstDisabledStepIndex !== -1 ? firstDisabledStepIndex + 1 : 100;

          setSetupRequired((prev) => (prev !== isRequired ? isRequired : prev));
          setStuckStepIndex((prev) =>
               prev !== newStuckStepIndex ? newStuckStepIndex : prev
          );
     }, [stepsData]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (statusRecreate) {
               fetchData();
          }
     }, [statusRecreate]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleRecreateConnection = () => {
          if (!ruleSetupConnectionId) return;

          callSalesforceApi(
               `${API_SALESFORCE_RECREATE_CONNECTION}/${ruleSetupConnectionId}`,
               "POST",
               {}
          ).then((response) => {
               if (response && response.status === 200) {
                    toast.success("Recreate Successfully!");
               } else {
                    toast.error("Recreate Rule Failed!");
               }
          });
     };

     const handleHasChange = () => {
          dispatch(
               setRuleHaveEditting({
                    show: true,
                    type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE,
               })
          );
     };

     const mappingComponents = [
          { key: "systemFieldsMapping", component: SystemFields },
          { key: "pipelineFieldsMapping", component: PipelineFields },
          { key: "valueFieldsMapping", component: ValueFields },
          { key: "relationshipFieldsMapping", component: RelationshipFields },
          { key: "targetFieldsMapping", component: TargetFields },
          { key: "otherFieldsMapping", component: OtherFields },
          { key: "setUpFieldsMapping", component: SetUpFields },
          { key: "customFieldsMapping", component: CustomFields },
          // { key: 'relationships', component: Relationships },
     ];

     const hasData = (obj) => obj !== undefined && obj.length > 0;

     const componentsWithData = mappingComponents.reduce(
          (components, { key, component }) => {
               if (
                    hasData(initStepsData[key]) ||
                    (!initStepsData.isDefault &&
                         [
                              "setUpFieldsMapping",
                              "relationshipFieldsMapping",
                              "valueFieldsMapping",
                         ].includes(key)) ||
                    (["Opportunity", "Order", "Quote", "Contract"].includes(
                         initStepsData.object
                    ) &&
                         key === "valueFieldsMapping")
               ) {
                    components.push({ component, index: components.length });
               }
               return components;
          },
          []
     );

     let count = componentsWithData.length;

     componentsWithData.push({ component: NameDescription, index: count++ });

     if (!SALESFORCE_OBJECT_RELATION.includes(initStepsData.object)) {
          componentsWithData.push({ component: HistoricalSync, index: count });
     }

     const systemFieldsMappingIndex = componentsWithData.findIndex(
          ({ component }) => component === SystemFields
     );
     if (systemFieldsMappingIndex !== -1) {
          componentsWithData.splice(systemFieldsMappingIndex + 1, 0, {
               component: PreviewObject,
               index: systemFieldsMappingIndex + 1,
          });

          // Update indices for components after the insertion
          for (
               let i = systemFieldsMappingIndex + 2;
               i < componentsWithData.length;
               i++
          ) {
               componentsWithData[i].index = i;
          }
     }

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail object-import-detail">
                    {ruleSetup ? (
                         <div
                              className="p-4 text-red-800 border border-red-300 rounded-lg bg-red-50"
                              role="alert"
                         >
                              <div className="d-flex align-items-center">
                                   <svg
                                        className="shrink-0 w-4 h-4 me-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                   >
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"></path>
                                   </svg>
                                   <span className="sr-only">Info</span>

                                   <div className="f-18 mb-0 fw-5">Rule is being setup</div>
                              </div>

                              <div className="mt-3 mb-4 text-sm">
                                   <p className="mb-1 text-red-800 f-14">
                                        The rule is currently being set up. Please wait a few minutes.
                                   </p>
                                   <p className="mb-0 text-red-800 f-14">
                                        If the setup takes too long, please click the button below to
                                        restart the setup process.
                                   </p>
                              </div>

                              <div className="d-flex">
                                   <button
                                        onClick={() => handleRecreateConnection()}
                                        type="button"
                                        className="button-setup-rule px-3 py-2 me-2 text-center d-inline-flex align-items-center rounded-3 border-0"
                                   >
                                        Recreate
                                   </button>
                              </div>
                         </div>
                    ) : (
                         <>
                              {isLoading ? (
                                   <CenterSpinner />
                              ) : (
                                   <div className="cvr-create-new">
                                        <h1>
                                             {id
                                                  ? `Edit: Salesforce Object Import - ${stepsData.name}`
                                                  : `Create: Salesforce Object Import`}
                                        </h1>
                                        <p>
                                             {id ? `Edit` : `Create a new`} external data source by
                                             configuring the settings below.
                                        </p>
                                        {logError && (
                                             <div className="relative">
                                                  <CAlert color="warning">
                                                       {stepsData.name} cannot be imported at the moment. Please
                                                       enable it in Salesforce. Click 'Confirm' to proceed with
                                                       importing other objects without any impacts.
                                                  </CAlert>

                                                  <CButton
                                                       color="primary"
                                                       className="position-absolute"
                                                       size="sm"
                                                       style={{
                                                            top: "50%",
                                                            right: "10px",
                                                            transform: "translateY(-50%)",
                                                       }}
                                                       onClick={handleRuleLogError}
                                                  >
                                                       <span>Confirm</span>
                                                  </CButton>
                                             </div>
                                        )}
                                        <ObjectDetailContext.Provider
                                             value={{
                                                  id,
                                                  activeStep,
                                                  setActiveStep,
                                                  initStepsData,
                                                  setInitStepsData,
                                                  stepsData,
                                                  setStepsData,
                                                  currentData,
                                                  setCurrentData,
                                                  handleHasChange,
                                                  listObjectsField,
                                                  listFields,
                                                  fetchData,
                                                  fetchDataGetNew,
                                                  fetchDataSelectFields,
                                                  handleGetNewField,
                                                  isLoadingGetNewField,
                                                  setIsLoadingGetNewField,
                                                  stepsMappingField,
                                                  setStepsMappingField,
                                                  fetchMappingField,
                                                  customFilter,
                                                  selectExistingState,
                                                  setSelectExistingFor,
                                                  setupRequired,
                                                  setSetupRequired,
                                                  stuckStepIndex,
                                             }}
                                        >
                                             <DataSourceConnection />
                                             {componentsWithData.map((mapping) => {
                                                  const { component: Component, index } = mapping;
                                                  return <Component key={index} step={index + 2} />;
                                             })}
                                        </ObjectDetailContext.Provider>
                                   </div>
                              )}
                         </>
                    )}
               </div>
          </React.Suspense>
     );
};

export default ObjectImportDetail;

import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRuleHaveEditting } from "../../../../../actions/common";
import { API_CLIENT_SALES_CONVERSION_DETAIL, TYPE_SHOW_UNSAVE_CHANGE } from "../../../../../constants";
import CenterSpinner from "../../../../general/Loadings/CenterSpinner";
import { callTokenApi } from "../../../../../apiCaller";
import { toastError } from "../../../../../utils";

const Step1 = React.lazy(() => import(`./steps/Step1`));
const Step2 = React.lazy(() => import(`./steps/Step2`));

export const SaleConversionDetailContext = React.createContext({});

const DEFAULT_DATA_SALE_CONVERSION = {
     name: "",
     description: "",
     conditions: {
          slug: "",
          ssTriggerId: "",
          type: "",
          value: "",
          currency: "",
          isManual: false,
          conversionValue: false,
          primaryObject: '',
          secondaryObject: []
     },
};
const SaleConversionDetail = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;
     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState(cloneDeep(DEFAULT_DATA_SALE_CONVERSION));
     const [initStepsData, setInitStepsData] = useState(cloneDeep(DEFAULT_DATA_SALE_CONVERSION));

     const fetchSaleConversionDetail = () => {
          if (!id) return;
          setIsLoading(true);
          callTokenApi(API_CLIENT_SALES_CONVERSION_DETAIL.replace(':accountId', accountId).replace(':id', id), 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { conversion } = response.data;
                         setStepsData(conversion);
                         setInitStepsData(conversion);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     useEffect(fetchSaleConversionDetail, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className='rule-detail segment-detail'>
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className='cvr-create-new'>
                              <h1>{id ? `Edit Sale Conversion: ${stepsData.name}` : `Create a Sale Conversion`}</h1>
                              <p>
                                   {id
                                        ? "Use the steps below to configure your Sale Conversion."
                                        : "Create your Sale Conversion by following the steps below. "}{" "}
                              </p>
                              <SaleConversionDetailContext.Provider
                                   value={{
                                        id,
                                        activeStep,
                                        setActiveStep,
                                        initStepsData,
                                        setInitStepsData,
                                        stepsData,
                                        setStepsData,
                                        handleHasChange,
                                   }}
                              >
                                   <Step1 />
                                   <Step2 />
                              </SaleConversionDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default SaleConversionDetail;

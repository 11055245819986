import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchDimensionsRequest, setListSegmentApplyReload, setReloadReportData } from '../../../../../actions/subscriber';
import { callTokenApi, callTokenApiCancel } from '../../../../../apiCaller';
import {
     API_CLIENT_GET_MAX_STEP_FORM_CATEGORY,
     API_CLIENT_REBUILD_SEGMENT,
     API_CLIENT_REPORTING_EXPLORER,
     API_CLIENT_REPORTING_EXPLORER_PROFILE,
     BQ_JOB_STATUS,
     CHECK_LIST_OPERATOR_OPTION,
     DEFAULT_DATE_FORMAT,
     EXCLUDE_CHECK_LIST_OPERATOR_OPTION,
     JOB_STATUS,
     PLATFORM_ACCOUNT_STATUSES,
     REPORT_DATA_TYPES,
     REPORT_NAMES,
} from '../../../../../constants';
import { toastError } from '../../../../../utils';
import { getCustomPropertiesReport, handleChangeColSort } from '../../../../../utils/UtilReport';
import { EventExplorerContext } from '../EventExplorer';
import { useDebouncedCallback } from '../../../../../helpers/customHooks';
import dayjs from 'dayjs';
import { SEGMENT_BYTES_BILLED_THRESHOLD } from '../../../../../constants/segment';

const useFetchData = (props) => {
     const {
          reportName,
          filterMetric,
          filterDimensions,
          filterProperties,
          filterEvent,
          setReportData,
          filterEventReport,
          setOffSet,
          scoped,
          reportType,
          conversionName,
          conversionNameMetric,
          dateRangeConfig,
          setReportCompare,
          setChangeData,
          recordProfile,
          setMetricsStepForm,
          customProperties,
          newProperties: propertiesAll,
          handleGetOption,
          weight,
          utmArray,
          setMaxStepForm,
          eCoItemCategories,
          offSet,
          setAttributeBeforeReload,
          setTempReport,
          reloadTable,
          attributeBeforeReload,
          listSegmentReportApply,
          setListSegmentReportApply,
          setTempScopedReport,
          isWebConversion,
          dimensionEvents,
          primaryObject,
          secondaryObject,
          salesConversionName
     } = useContext(EventExplorerContext);
     const reqSegments = !recordProfile ? listSegmentReportApply : [];
     const {
          setIsLoading,
          itemsPerPage,
          activePage,
          sort,
          setShowLongLoading,
          finalActivePage,
          pagination,
          reloadReportData,
          setIsLoadingFetch,
          setReportIsCancel,
          setIsRebuildLoading,
     } = props;

     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
          metrics = handleGetOption({ option: metrics, type: 'weight', reportName, weight });
     }
     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }

     if ([REPORT_NAMES.BY_CONVERSION, REPORT_NAMES.BY_PROPERTY].includes(reportName)) {
          dimensions = [...dimensions, ...propertiesAll];
     }
     const { id: accountId, platformAccountStatus, timeZone: activeAccountTimeZone } = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const listSegmentReload = useSelector((state) => state.subscriber.listSegmentReload);
     const listSegment = useSelector((state) => state.subscriber.listSegment);
     const rebuildSegment = useSelector((state) => state.subscriber.rebuildSegment);
     const segmentJobId = useSelector((state) => state.subscriber.segmentJobId);

     const [skipFetch, setSkipFetch] = useState(false);
     const refJobId = useRef('');
     const timeCallApi = useRef(1500);

     const dependencies = [
          itemsPerPage,
          activePage,
          reloadTable,
          filterEventReport,
          sort.fieldName,
          sort.isDesc,
          dateRangeReports,
          pagination,
          weight,
          listSegmentReload,
          primaryObject
     ];

     const [cancelApi, setCancelApi] = useState({});

     const dispatch = useDispatch();

     const useQuery = () => {
          const { search } = useLocation();

          return React.useMemo(() => new URLSearchParams(search), [search]);
     };
     const query = useQuery();
     const dataConversionID = query.get('conversionID');

     const newDateRange = {
          selection: {
               startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };
     if (dateRangeReports.compare) {
          newDateRange.compare = {
               startDate: dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT),
          };
     }
     if (dateRangeConfig.dateScope) {
          newDateRange.dateScope = dateRangeConfig.dateScope;
     }

     let filterConversionID;
     if (dataConversionID) {
          filterConversionID = [
               {
                    type: 'conversionId',
                    value: dataConversionID,
                    operator: 'eq',
               },
          ];
     }

     const handleOrderCol = (arr, arrFilter) => {
          const newArr = [];
          arr.forEach((item) => {
               if (arrFilter.some((el) => el.value === item.key)) {
                    newArr.push(item.key);
               }
          });
          return newArr;
     };
     const newDimensions = handleOrderCol(dimensions, filterDimensions);
     const newMetric = handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric).map((i) => i.key);
     const newProperties = handleOrderCol(propertiesAll, filterProperties);
     const filter = dataConversionID ? filterConversionID : filterEvent;
     const newFilter = filter.map((item) => {
          const newItem = { ...item };

          if (item.operator === CHECK_LIST_OPERATOR_OPTION.value || item.operator === EXCLUDE_CHECK_LIST_OPERATOR_OPTION.value) {
               newItem.value = item.value && Array.isArray(item.value) ? [...item.value].map((el) => el.value) : [];
          }
          delete newItem['optionsOperator'];
          return newItem;
     });
     const fetchDimensions = () => {
          dispatch(fetchDimensionsRequest(accountId));
     };

     useEffect(fetchDimensions, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

     let bodyData = {
          reportName,
          accountId,
          dateRanges: newDateRange,
          limit: itemsPerPage,
          offset: offSet,
          metrics: newMetric,
          properties: newProperties,
          dimensions: newDimensions,
          filter: newFilter,
          sort: sort,
          reportType: reportType,
          scoped: scoped,
          conversionName: conversionName.filter(
               (conversion) =>
                    newMetric.some((metric) => metric.includes(conversion.convId)),
          ),
          eCommerceName: conversionName.filter((conversion) => newMetric.includes(conversion.value) && conversion.label.includes('Ecommerce')),
          primaryObject,
          secondaryObject,
          salesConversionName,
          isCompare: dateRangeConfig.comparePeriod,
          recordProfile: recordProfile ? recordProfile : (filter && filter[0] && filter[0].filterRecord ? filter[0].filterRecord : false),
          customProperties: [REPORT_NAMES.BY_CONVERSION,REPORT_NAMES.BY_PROPERTY].includes(reportName) ? getCustomPropertiesReport(customProperties, newDimensions) : getCustomPropertiesReport(customProperties, newProperties),
          utmArray,
          weight,
          segments: reqSegments,
          attributeBeforeReload: attributeBeforeReload.scoped !== scoped ? { dimensions: [], properties: [], metrics: [], scoped: '' } : attributeBeforeReload,
     };

     if (reportName === 'conversionEvents') {
          bodyData.isWebConversion = isWebConversion;
          bodyData.dimensionEvent = dimensionEvents;
     }

     const handleGenerationMetricsStepForm = (number) => {
          const metricsStepForm = [];
          for (let i = 1; i <= number; i++) {
               metricsStepForm.push(
                    { key: `step${i}View`, label: `Step ${i} - View` },
                    { key: `step${i}Start`, label: `Step ${i} - Start` },
                    { key: `step${i}Complete`, label: `Step ${i} - Complete` }
               );
          }
          return metricsStepForm;
     };
     const callApi = ({ API_NAME, apiCancel = false, timeout }) => {
          //  Block calls to the API if there is an unready segment
          if (reqSegments.some((seg) => seg.queryStatus !== BQ_JOB_STATUS.DONE)) return;

          const apiFunction = apiCancel ? callTokenApiCancel : callTokenApi;
          refJobId.current = '';
          apiFunction(API_NAME, 'POST', bodyData).then((response) => {
               if (response && response.status === 200) {
                    const { overallRowCount, rows, event, totalsRow, totalRows, message, jobId, temp, tempCompare, tempScoped, tempScopedCompare, isCompare } =
                         response.data;

                    if (message) {
                         if (jobId) {
                              refJobId.current = jobId;
                         }
                         setAttributeBeforeReload({
                              dimensions: bodyData.dimensions,
                              properties: bodyData.properties,
                              metrics: bodyData.metrics,
                              scoped: bodyData.scoped,
                         });
                         setTempScopedReport({ tempScoped, tempScopedCompare });
                         setIsLoadingFetch(true);
                    } else {
                         setReportData({ overallRowCount, rows, event, totalsRow });
                         if (isCompare) {
                              setReportCompare({ rows, totalsRow: totalRows });
                         } else {
                              setReportCompare({ rows: null, totalsRow: 0 });
                         }

                         setIsLoadingFetch(false);
                         setIsLoading(false);
                         setChangeData(false);
                         if (attributeBeforeReload && attributeBeforeReload.dimensions && attributeBeforeReload.dimensions.length === 0) {
                              setAttributeBeforeReload({
                                   dimensions: bodyData.dimensions,
                                   properties: bodyData.properties,
                                   metrics: bodyData.metrics,
                                   scoped: bodyData.scoped,
                              });
                         }
                         if (temp || tempCompare) {
                              setTempReport({ temp, tempCompare });
                         }
                         setTempScopedReport({ tempScoped, tempScopedCompare });
                         if (timeout) {
                              clearTimeout(timeout);
                         }

                         setShowLongLoading(false);
                         // dispatch(setReloadReportData({ ...reloadReportData, tempTables: null }));
                         dispatch(setListSegmentApplyReload(false));
                         timeCallApi.current = 100;
                    }
               } else {
                    response && toastError(response);
               }
          });
     };

     const callApiFormReport = ({ API_NAME, apiCancel = false, timeout }) => {
          const apiFunction = apiCancel ? callTokenApiCancel : callTokenApi;
          if (cancelApi[API_CLIENT_REPORTING_EXPLORER]) {
               cancelApi[API_CLIENT_REPORTING_EXPLORER].cancel('Request cancelled due to a new request.');
          }
          refJobId.current = '';
          apiFunction(API_NAME, 'POST', bodyData, setCancelApi)
               .then((response) => {
                    if (response && response.status === 200) {
                         const dataMetricFormStep = {};

                         dataMetricFormStep.metricsStepForm = handleGenerationMetricsStepForm(response.data.maxStepForm);
                         setMetricsStepForm(dataMetricFormStep.metricsStepForm);
                         dataMetricFormStep.maxStepForm = response.data.maxStepForm;

                         return dataMetricFormStep;
                    } else {
                         // setIsLoading(false);
                         // setChangeData(false);
                         // if (id) {
                         //      clearTimeout(id);
                         //      setShowLongLoading(false);
                         // }
                         response && toastError(response);
                    }
               })
               .then((res) => {
                    if (res) {
                         const valueFilterMetric = filterMetric.map((item) => item.value);
                         // const stepMetricsAdded = res.metricsStepForm.filter((item) => valueFilterMetric.includes(item.key));
                         // const newMetricStepForm = handleChangeColSort([...stepMetricsAdded], [...handleGetOption({ option: stepMetricsAdded })]).map(
                         //      (i) => i.key
                         // );
                         bodyData.metrics = [...valueFilterMetric];

                         bodyData.maxStepForm = res.maxStepForm;
                         setMaxStepForm(res.maxStepForm);
                         callTokenApiCancel(API_CLIENT_REPORTING_EXPLORER, 'POST', bodyData, setCancelApi).then((response) => {
                              if (response && response.status === 200) {
                                   const { overallRowCount, rows, event, totalsRow, totalRows, message, jobId, temp, tempScoped, tempScopedCompare, isCompare } =
                                        response.data;
                                   if (temp) {
                                        setTempReport({ temp, tempCompare: '' });
                                   }
                                   if (message) {
                                        if (jobId) {
                                             refJobId.current = jobId;
                                        }
                                        setAttributeBeforeReload({
                                             dimensions: bodyData.dimensions,
                                             properties: bodyData.properties,
                                             metrics: bodyData.metrics,
                                             scoped: bodyData.scoped,
                                        });
                                        setTempScopedReport({ tempScoped, tempScopedCompare });
                                        setIsLoadingFetch(true);
                                   } else {
                                        setIsLoadingFetch(false);
                                        setReportData({ overallRowCount, rows, event, totalsRow });
                                        if (isCompare) {
                                             setReportCompare({ rows, totalsRow: totalRows });
                                        } else {
                                             setReportCompare({ rows: null, totalsRow: 0 });
                                        }

                                        setIsLoading(false);
                                        setChangeData(false);
                                        setShowLongLoading(false);
                                        if (attributeBeforeReload && attributeBeforeReload.dimensions && attributeBeforeReload.dimensions.length === 0) {
                                             setAttributeBeforeReload({
                                                  dimensions: bodyData.dimensions,
                                                  properties: bodyData.properties,
                                                  metrics: bodyData.metrics,
                                                  scoped: bodyData.scoped,
                                             });
                                        }
                                        setTempScopedReport({ tempScoped, tempScopedCompare });
                                        if (timeout) {
                                             clearTimeout(timeout);
                                        }

                                        // dispatch(setReloadReportData({ ...reloadReportData, tempTables: null }));
                                        dispatch(setListSegmentApplyReload(false));
                                   }
                              } else {
                                   response && toastError(response);
                              }
                         });
                    }
               });
     };

     // .finally(() => {
     //      if (returnToPage1) {
     //           setSkipFetch(true);
     //           setActivePage(1);
     //      }
     // });

     let timeout;

     const fetchData = (refresh) => {
          if (
               platformAccountStatus === PLATFORM_ACCOUNT_STATUSES.SANDBOX &&
               !localStorage.getItem('llByPassSandbox') &&
               !window.location.search.includes('bypass-sandbox=1')
          ) {
               setReportData({ overallRowCount: 0, rows: [], totalsRow: 0 });
               setReportCompare({ rows: null, totalsRow: 0 });
               setIsLoading(false);
               setChangeData(false);
               setShowLongLoading(false);
               dispatch(
                    setReloadReportData({
                         ...reloadReportData,
                         tableName: '',
                    })
               );
               setIsLoadingFetch(false);
               if (timeout) {
                    clearTimeout(timeout);
               }

               if (reloadReportData.status === JOB_STATUS.CANCELED) {
                    setReportIsCancel(true);
               }
               return;
          }
          if (activePage === 1 && skipFetch) {
               setSkipFetch(false);
               return;
          }
          if (!refresh) {
               setOffSet((finalActivePage - 1) * itemsPerPage);
          }
          if (filterDimensions.length === 0) return;

          setReportIsCancel(false);

          if (!refresh) {
               // eslint-disable-next-line
               timeout = setTimeout(() => {
                    setChangeData(false);
                    setShowLongLoading(true);
               }, 6000);
          } else {
               if (timeout) {
                    clearTimeout(timeout);
               }
          }

          if (recordProfile) {
               callApi({ API_NAME: API_CLIENT_REPORTING_EXPLORER_PROFILE.replace(':reportName', reportName), apiCancel: true, timeout });
          } else {
               if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT) {
                    callApiFormReport({ API_NAME: API_CLIENT_GET_MAX_STEP_FORM_CATEGORY, apiCancel: true, timeout });
               } else {
                    callApi({ API_NAME: API_CLIENT_REPORTING_EXPLORER, apiCancel: true, timeout });
               }
          }

          // return () => {
          //      if (timeout) {
          //           clearTimeout(timeout);
          //      }
          // };
     };

     useEffect(() => {
          timeCallApi.current = 1500;
     }, [scoped]);

     const handleRebuildSegment = (segmentIds) => {
          const payload = { accountId, segmentIds };

          setIsRebuildLoading(true);
          refJobId.current = '';
          callTokenApi(API_CLIENT_REBUILD_SEGMENT, 'POST', payload)
               .then((response) => {
                    if (response.status === 200) {
                         const { jobId } = response.data;
                         refJobId.current = jobId;
                    } else {
                         toastError(response);
                    }
               });
     };

     // Stabilize the fetch function
     const handleFetchData = useCallback(() => {
          // Rebuild segments
          const currentTime = dayjs().tz(activeAccountTimeZone);
          const rebuildSegmentFilter = (seg) => seg.totalBytesBilled > SEGMENT_BYTES_BILLED_THRESHOLD && currentTime.diff(dayjs(seg.buildAt).tz(activeAccountTimeZone), 'hour') > 12;
          const rebuildSegmentIds = reqSegments.filter(rebuildSegmentFilter).map((el) => el.id);

          if (rebuildSegmentIds.length > 0) {
               handleRebuildSegment(rebuildSegmentIds);
          } else {
               setIsRebuildLoading(false);
               fetchData();
          }
          // Replace with your actual API call
     }, dependencies); //eslint-disable-line

     // Debounce the fetchData function
     const [debouncedFetchData, cancelDebounce] = useDebouncedCallback(handleFetchData, timeCallApi.current);

     useEffect(() => {
          setIsLoading(true);
          debouncedFetchData();

          // Cleanup to cancel the debounce when the component unmounts
          return () => {
               cancelDebounce();
          };
     }, [debouncedFetchData, cancelDebounce, ...dependencies]); //eslint-disable-line

     // Refresh api reporting explorer
     useEffect(() => {
          if (reloadReportData && reloadReportData.jobId === refJobId.current) {
               const { status, tableName, comparisonTableName, segmentTables, comparisonSegmentTables } = reloadReportData;

               if (
                    status === BQ_JOB_STATUS.DONE &&
                    (platformAccountStatus !== PLATFORM_ACCOUNT_STATUSES.SANDBOX ||
                         localStorage.getItem('llByPassSandbox') ||
                         window.location.search.includes('bypass-sandbox=1'))
               ) {
                    if (reloadReportData.reportName === reportName) {
                         if (tableName) {
                              bodyData.tableName = tableName;
                         }
                         if (comparisonTableName) {
                              bodyData.comparisonTableName = comparisonTableName;
                         }
                         if (segmentTables) {
                              bodyData.segmentTables = segmentTables;
                         }
                         if (comparisonSegmentTables) {
                              bodyData.comparisonSegmentTables = comparisonSegmentTables;
                         }
                    }
                    fetchData(true);
               } else {
                    setReportData({ overallRowCount: 0, rows: [], totalsRow: 0 });
                    setReportCompare({ rows: null, totalsRow: 0 });
                    setIsLoading(false);
                    setChangeData(false);
                    setShowLongLoading(false);
                    // dispatch(setReloadReportData({ ...reloadReportData, tempTables: null }));
                    setIsLoadingFetch(false);
                    if (timeout) {
                         clearTimeout(timeout);
                    }

                    if (status === BQ_JOB_STATUS.CANCELED) {
                         setReportIsCancel(true);
                    }
               }
          }
     }, [reloadReportData.refreshRequest]); // eslint-disable-line

     // Fetch api reporting explorer when rebuild segment completes
     useEffect(() => {
          if (segmentJobId === refJobId.current) {
               setListSegmentReportApply((prevSegments) => {
                    return prevSegments.map((seg) => {
                         const newSegment = Array.isArray(listSegment) && listSegment.find((el) => el.id === seg.id);

                         if (newSegment) {
                              seg.buildAt = newSegment.buildAt;
                              seg.totalBytesBilled = newSegment.totalBytesBilled;
                         }

                         return seg;
                    });
               });

               fetchData();
               setIsRebuildLoading(false);
          }
     }, [rebuildSegment]); // eslint-disable-line
};

export default useFetchData;

import React from 'react';

import { SUBSCRIBER_PATH, SUBSCRIBER_MY_ACCOUNT_PATH, REPORT_NAMES, REPORT_TYPE, NEW_NAVIGATION_PATH } from '../constants';

// const Dashboard = React.lazy(() => import('../components/cms/subscriber/dashboard/Dashboard'));
const Dashboard = React.lazy(() => import('../components/cms/subscriber/dashboard-v2/DashboardV2'));
const NoAccountDashboard = React.lazy(() => import('../components/cms/subscriber/dashboard/NoAccountDashboard'));
const AccountSettings = React.lazy(() => import('../components/cms/subscriber/dashboard/AccountSettings'));
const Users = React.lazy(() => import('../components/cms/subscriber/dashboard/Users'));
const Versions = React.lazy(() => import('../components/cms/subscriber/dashboard/Versions'));
const Profile = React.lazy(() => import('../components/cms/subscriber/profile/Profile'));
const EmailNotifications = React.lazy(() => import('../components/cms/subscriber/email-notifications/EmailNotifications'));
const Affiliate = React.lazy(() => import('../components/cms/subscriber/affiliate/Affiliate'));
const Website = React.lazy(() => import('../components/cms/subscriber/website/Website'));
const DataLayerVariables = React.lazy(() => import('../components/cms/subscriber/data-layer-variables/DataLayerVariables'));
const BrowserStorageVariables = React.lazy(() => import('../components/cms/subscriber/browser-storage-variables/BrowserStorageVariables'));
const Tickets = React.lazy(() => import('../components/cms/subscriber/support-tickets/tickets/Tickets'));
const TicketDetails = React.lazy(() => import('../components/cms/subscriber/support-tickets/ticket-details/TicketDetails'));
const MyAccounts = React.lazy(() => import('../components/cms/subscriber/my-accounts/MyAccounts'));
const Masterclass = React.lazy(() => import('../components/cms/subscriber/masterclass/Masterclass'));
const Lesson = React.lazy(() => import('../components/cms/subscriber/masterclass/lesson/Lesson'));
const Certification = React.lazy(() => import('../components/cms/subscriber/certification/Certification'));
const OfficeHours = React.lazy(() => import('../components/cms/subscriber/office-hours/OfficeHours'));
const BecomeAPartner = React.lazy(() => import('../components/cms/subscriber/become-a-partner/BecomeAPartner'));
const PaymentMethods = React.lazy(() => import('../components/cms/subscriber/payment-methods/PaymentMethods'));
const PlanUsage = React.lazy(() => import('../components/cms/subscriber/dashboard/PlanUsage'));
const BillingInvoices = React.lazy(() => import('../components/cms/subscriber/dashboard/BillingInvoices'));
const ConsentListener = React.lazy(() => import('../components/cms/subscriber/consent-privacy/consent-listener'));
const TriggersAndConversions = React.lazy(() => import('../components/cms/subscriber/trigger-conversions'));
const CustomDataLayerVariables = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/index'));
const CustomBrowserStorageVariables = React.lazy(() =>
     import('../components/cms/subscriber/listener-settings-v2/custom-browser-storage-variables/index')
);
const CustomFormCategories = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/custom-form-categories/index'));
const CustomMonitoringRules = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/monitoring/Monitorings'));
// const ComingSoon = React.lazy(() => import('../components/cms/subscriber/dashboard/ComingSoon'));
const InsightsDataLayer = React.lazy(() => import('../components/cms/subscriber/event-reporting/EventReporting'));
const ReportTool = React.lazy(() => import('../components/cms/subscriber/analytics/report-tool/ReportTool'));
const InsightsComingSoon = React.lazy(() => import('../components/cms/subscriber/dashboard/InsightsComingSoon'));
const MonitoringComingSoon = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/monitoring/MonitoringComingSoon'));
const MonitoringNotificationsV2 = React.lazy(() => import('../components/cms/subscriber/notifications/MonitoringNotificationsV2'));
const MonitoringNotificationDetailV2 = React.lazy(() => import('../components/cms/subscriber/notifications/MonitoringNotificationDetailV2'));
const MonitoringConversions = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/monitoring/MonitoringConversions'));
const MonitoringListeners = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/monitoring/MonitoringListeners'));
const MonitoringDestinations = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/monitoring/MonitoringDestinations'));
const MonitoringFormCategories = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/monitoring/MonitoringFormCategories'));
const PersonalData = React.lazy(() => import('../components/cms/subscriber/consent-privacy/personal-data'));
const ConsentRules = React.lazy(() => import('../components/cms/subscriber/consent-privacy/consent-rules'));
const DataDeletionRequest = React.lazy(() => import('../components/cms/subscriber/consent-privacy/data-deletion-requests'));
const DataCategories = React.lazy(() => import('../components/cms/subscriber/consent-privacy/data-categories'));
const ConsenLogs = React.lazy(() => import('../components/cms/subscriber/consent-privacy/consent-logs'));
const EventExplorer = React.lazy(() => import('../components/cms/subscriber/analytics/EventExplorer'));
const PopulateFormFields = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/populate-form-fields/index.js'));
const DataTransformations = React.lazy(() => import('../components/cms/subscriber/listener-settings-v2/other-data-transformations'));
// const GoalsLayout = React.lazy(() => import('../components/cms/subscriber/goals/GoalsLayout'));

// custom reports
const CustomReports = React.lazy(() => import('../components/cms/subscriber/analytics/custom-report/index.js'));
const CustomReportDetails = React.lazy(() => import('../components/cms/subscriber/analytics/custom-report/CustomReportDetails'));
const MetricsAndDimensions = React.lazy(() => import('../components/cms/subscriber/analytics/data-settings/metrics-and-dimensions/index.js'));
const Channels = React.lazy(() => import('../components/cms/subscriber/analytics/data-settings/channels/index.js'));
const Segment = React.lazy(() => import('../components/cms/subscriber/analytics/data-settings/manager-segment/index.js'));

// reveal
const Reveal = React.lazy(() => import('../components/cms/subscriber/sources/reveal/index'));
const RevealPeople = React.lazy(() => import('../components/cms/subscriber/sources/reveal/RevealPeople'));
const RevealedCompanies = React.lazy(() => import('../components/cms/subscriber/sources/reveal/RevealedCompanies'));

// external-data
const FromFile = React.lazy(() => import('../components/cms/subscriber/sources/external-data/from-file/index.js'));
const GoogleAds = React.lazy(() => import('../components/cms/subscriber/sources/external-data/google-ads/index.js'));
const FacebookAds = React.lazy(() => import('../components/cms/subscriber/sources/external-data/facebook-ads/index.js'));
const Salesforce = React.lazy(() => import('../components/cms/subscriber/sources/external-data/salesforce/index.js'));
const TikTok = React.lazy(() => import('../components/cms/subscriber/sources/external-data/tiktok/index.js'));
const MicrosoftAds = React.lazy(() => import('../components/cms/subscriber/sources/external-data/microsoft-ads/index.js'));
const LinkedinAds = React.lazy(() => import('../components/cms/subscriber/sources/external-data/linkedin-ads/index.js'));

//save reports
const SavedReports = React.lazy(() => import('../components/cms/subscriber/analytics/saved-reports/index.js'));

// Overview
const EcommerceOverview = React.lazy(() => import('../components/cms/subscriber/dashboard-v2/EcommerceOverview'));
const PerformanceOverview = React.lazy(() => import('../components/cms/subscriber/dashboard-v2/PerformanceOverview'));
const WebEngagementOverview = React.lazy(() => import('../components/cms/subscriber/dashboard-v2/WebEngagementOverview'));
const WebConversionsOverview = React.lazy(() => import('../components/cms/subscriber/dashboard-v2/WebConversionsOverview'));

let reportRoutes = [];

if (localStorage.getItem('llShowNewDashboard') || window.location.search.includes('show-new-dashboard=1')) {
     if (!localStorage.getItem('llShowNewDashboard')) {
          localStorage.setItem('llShowNewDashboard', '1');
     }
}

if (localStorage.getItem('llShowData') || window.location.search.includes('show-data=1')) {
     if (!localStorage.getItem('llShowData')) {
          localStorage.setItem('llShowData', '1');
     }
}

if (localStorage.getItem('llRevealedPeople') || window.location.search.includes('revealed-people=1')) {
     if (!localStorage.getItem('llRevealedPeople')) {
          localStorage.setItem('llRevealedPeople', '1');
     }
}

if (localStorage.getItem('llByPassSandbox') || window.location.search.includes('bypass-sandbox=1')) {
     if (!localStorage.getItem('llByPassSandbox')) {
          localStorage.setItem('llByPassSandbox', '1');
     }
}

reportRoutes = [
     //Attributes
     {
          path: SUBSCRIBER_PATH.INSIGHTS_CONSENT,
          name: 'Consent',
          component: EventExplorer,
          props: { title: 'Consent Details', reportName: REPORT_NAMES.CONSENT_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_GEOGRAPHY,
          name: 'Geography',
          component: EventExplorer,
          props: { title: 'Geography Details', reportName: REPORT_NAMES.GEOGRAPHY_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_DEVICES,
          name: 'Devices',
          component: EventExplorer,
          props: { title: 'Device Details', reportName: REPORT_NAMES.DEVICE_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_BROWSERS,
          name: 'Browsers',
          component: EventExplorer,
          props: { title: 'Browser Details', reportName: REPORT_NAMES.BROWSER_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     //Acquisition
     // { path: SUBSCRIBER_PATH.INSIGHTS_SOURCE, name: 'Channel', component: EventExplorer, props: { title: 'Channel Details', reportName: REPORT_NAMES.CHANNEL_REPORT, reportType: REPORT_TYPE.ACQUISITION } },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SOURCE,
          name: 'Source',
          component: EventExplorer,
          props: { title: 'Source Details', reportName: REPORT_NAMES.SOURCE_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_UTMS,
          name: 'UTMs',
          component: EventExplorer,
          props: { title: 'UTMs Details', reportName: REPORT_NAMES.UTM_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_REFERRALS,
          name: 'Referrals',
          component: EventExplorer,
          props: { title: 'Referrals Details', reportName: REPORT_NAMES.REFERRALS_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_CHANNEL,
          name: 'Channels',
          component: EventExplorer,
          props: { title: 'Channel Details', reportName: REPORT_NAMES.CHANNEL_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     //Engagement
     {
          path: SUBSCRIBER_PATH.INSIGHTS_NEW_RETURNING,
          name: 'New vs Returning',
          component: EventExplorer,
          props: { title: 'New vs Returning Details', reportName: REPORT_NAMES.NEW_RETURNING_REPORT, reportType: REPORT_TYPE.ENGAGEMENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SESSION_COUNT,
          name: 'Session Count',
          component: EventExplorer,
          props: { title: 'Session Count Details', reportName: REPORT_NAMES.SESSION_COUNT_REPORT, reportType: REPORT_TYPE.ENGAGEMENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SESSION_DURATION,
          name: 'Session Duration',
          component: EventExplorer,
          props: { title: 'Session Duration Details', reportName: REPORT_NAMES.SESSION_DURATION_REPORT, reportType: REPORT_TYPE.ENGAGEMENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_COUNT,
          name: 'Conversion count',
          component: EventExplorer,
          props: { title: 'Conversion count Details', reportName: REPORT_NAMES.CONVERSION_COUNT_REPORT, reportType: REPORT_TYPE.ENGAGEMENT },
     },
     //Content
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ALL_PAGES,
          name: 'All Pages',
          component: EventExplorer,
          props: { title: 'All Pages', reportName: REPORT_NAMES.ALL_PAGE_REPORT, reportType: REPORT_TYPE.CONTENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_LANDING_PAGES,
          name: 'Landing Pages',
          component: EventExplorer,
          props: { title: 'Landing Pages', reportName: REPORT_NAMES.LANDING_PAGE_REPORT, reportType: REPORT_TYPE.CONTENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_EXIT_PAGES,
          name: 'Exit Pages',
          component: EventExplorer,
          props: { title: 'Exit Pages', reportName: REPORT_NAMES.EXIT_PAGE_REPORT, reportType: REPORT_TYPE.CONTENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ENTRANCE_EXIT_PAGES,
          name: 'Entrance And Exit Pages',
          component: EventExplorer,
          props: { title: 'Entrance And Exit Pages', reportName: REPORT_NAMES.ENTRANCE_AND_EXIT_REPORT, reportType: REPORT_TYPE.CONTENT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SCROLL_DEPTH,
          name: 'Scroll Depth',
          component: InsightsComingSoon,
          props: { title: 'Scroll Depth Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_VISIBLE_TIME,
          name: 'Visible Time',
          component: InsightsComingSoon,
          props: { title: 'Visible Time Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PAGE_FLOW,
          name: 'Page Flow',
          component: InsightsComingSoon,
          props: { title: 'Page Flow Detail', reportName: REPORT_NAMES.USER_DETAILS },
     },
     //Conversions
     // {
     //      path: SUBSCRIBER_PATH.INSIGHTS_ALL_CONVERSIONS,
     //      name: 'All Conversions',
     //      component: EventExplorer,
     //      props: { title: 'All Conversions Details', reportName: REPORT_NAMES.ALL_CONVERSION_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     // },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_BY_CONVERSION,
          name: 'By Conversion',
          component: EventExplorer,
          props: { title: 'By Conversion', reportName: REPORT_NAMES.BY_CONVERSION, reportType: REPORT_TYPE.CONVERSIONS },
     },
     {  
          path: SUBSCRIBER_PATH.INSIGHTS_BY_PROPERTY,
          name: 'By Property',
          component: EventExplorer,
          props: { title: 'By Property', reportName: REPORT_NAMES.BY_PROPERTY, reportType: REPORT_TYPE.CONVERSIONS },
     },
     // {
     //      path: SUBSCRIBER_PATH.INSIGHTS_BY_URL,
     //      name: 'By URL',
     //      component: EventExplorer,
     //      props: { title: 'By URL Details', reportName: REPORT_NAMES.BY_URL_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     // },
     // {
     //      path: SUBSCRIBER_PATH.INSIGHTS_BY_SOURCE,
     //      name: 'By Source',
     //      component: EventExplorer,
     //      props: { title: 'By Source Details', reportName: REPORT_NAMES.BY_SOURCE_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     // },
     // {
     //      path: SUBSCRIBER_PATH.INSIGHTS_BY_UTM,
     //      name: 'By UTM',
     //      component: EventExplorer,
     //      props: { title: 'By UTM Details', reportName: REPORT_NAMES.BY_UTM_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     // },
     // {
     //      path: SUBSCRIBER_PATH.INSIGHTS_BY_LANDING_PAGE,
     //      name: 'By Landing Page',
     //      component: EventExplorer,
     //      props: { title: 'By Landing Page Details', reportName: REPORT_NAMES.BY_LANDING_PAGE_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     // },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_BY_PAGE_JOURNEY,
          name: 'By page Journey',
          component: InsightsComingSoon,
          props: { title: 'By page Journey Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ATTRIBUTION,
          name: 'Attribution',
          component: EventExplorer,
          props: { title: 'Attribution Details', reportName: REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     },
     //eCommerce
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_FLOW,
          name: 'Purchase Flow',
          component: EventExplorer,
          props: { title: 'Purchase Flow', reportName: REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW, reportType: REPORT_TYPE.ECOMMERCE },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_ITEMS,
          name: 'Items',
          component: EventExplorer,
          props: { title: 'Items', reportName: REPORT_NAMES.ECOMMERCE_ITEMS, reportType: REPORT_TYPE.ECOMMERCE },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_ACTIVITY,
          name: 'Purchase Activity',
          component: EventExplorer,
          props: { title: 'Purchase Activity', reportName: REPORT_NAMES.ECOMMERCE_PURCHASE_ACTIVITY, reportType: REPORT_TYPE.ECOMMERCE },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_TRANSACTIONS,
          name: 'Purchase Transaction',
          component: EventExplorer,
          props: { title: 'Purchase Transaction', reportName: REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION, reportType: REPORT_TYPE.ECOMMERCE },
     },
     
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_OVERVIEW,
          name: 'Overview',
          component: InsightsComingSoon,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_TRANSACTIONS,
          name: 'Transactions',
          component: InsightsComingSoon,
          props: { title: 'Transactions Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_SOURCE,
          name: 'By Source eCommerce',
          component: InsightsComingSoon,
          props: { title: 'By Source eCommerce Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_UTM,
          name: 'By UTM eCommerce',
          component: InsightsComingSoon,
          props: { title: 'By UTM Details eCommerce', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PRODUCT_PERFORMANCE,
          name: 'Product Performance',
          component: InsightsComingSoon,
          props: { title: 'Product Performance Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PURCHASE_FUNNEL,
          name: 'Purcase Funnel',
          component: InsightsComingSoon,
          props: { title: 'Purcase Funnel Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     //Explore Data
     {
          path: SUBSCRIBER_PATH.INSIGHTS_EVENTS,
          name: 'Events',
          component: EventExplorer,
          props: { title: 'Event Details', reportName: REPORT_NAMES.EVENT_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_EXPLORE_CONVERSIONS,
          name: 'Explore Conversions',
          component: EventExplorer,
          props: { title: 'Conversion Details', reportName: REPORT_NAMES.CONVERSION_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SESSIONS,
          name: 'Sessions',
          component: EventExplorer,
          props: { title: 'Session Details', reportName: REPORT_NAMES.SESSION_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SESSION_REPLAYS,
          name: 'Session Replays',
          component: InsightsComingSoon,
          props: { title: 'Session Replays', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_USERS,
          name: 'Users',
          component: EventExplorer,
          props: { title: 'User Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PEOPLE,
          name: 'People',
          component: EventExplorer,
          props: { title: 'People Details', reportName: REPORT_NAMES.PERSON_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PEOPLE,
          name: 'People',
          component: EventExplorer,
          props: { title: 'People Details', reportName: REPORT_NAMES.PERSON_DETAILS_PROFILE },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PAGE_VIEW_EXPLORER,
          name: 'Page View Explorer',
          component: EventExplorer,
          props: { title: 'Page View Details', reportName: REPORT_NAMES.PAGE_VIEW_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_EVENTS,
          name: 'Conversion Events',
          component: EventExplorer,
          props: { title: 'Conversion Events', reportName: REPORT_NAMES.CONVERSION_EVENTS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_FORM_SUBMISSION_EVENTS,
          name: 'Form Submission Events',
          component: EventExplorer,
          props: { title: 'Form Submission Events', reportName: REPORT_NAMES.FORM_SUBMISSION_EVENTS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_FORM_CATEGORY,
          name: 'Form Details',
          component: EventExplorer,
          props: { title: 'Form Details', reportName: REPORT_NAMES.FORM_CATEGORY_REPORT },
     },
     // Sales Conversion 
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SALES_CONVERSION_BY_CONVERSION,
          name: 'By Conversion',
          component: EventExplorer,
          props: { title: 'By Conversion', reportName: REPORT_NAMES.SALES_CONVERSION_BY_CONVERSION, reportType: REPORT_TYPE.SALES_CONVERSION },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SALES_CONVERSION_BY_PROPERTY,
          name: 'By Property',
          component: EventExplorer,
          props: { title: 'By Property', reportName: REPORT_NAMES.SALES_CONVERSION_BY_PROPERTY, reportType: REPORT_TYPE.SALES_CONVERSION },
     },
     //report view
     { path: SUBSCRIBER_PATH.SAVE_REPORTS, name: 'save reports', component: SavedReports },

     { path: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORTS, name: 'Custom Reports / Manage Reports', component: CustomReports, exact: true },
     { path: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORT_DETAIL, component: CustomReportDetails },
     { path: SUBSCRIBER_PATH.INSIGHTS_REPORT_TOOL, name: 'Report Tool', component: ReportTool },
     { path: SUBSCRIBER_PATH.INSIGHTS_METRICS_AND_DIMENSIONS, name: 'Metrics and Dimensions', component: MetricsAndDimensions },
     { path: SUBSCRIBER_PATH.SEGMENT, name: 'Segment', component: Segment },
     { path: SUBSCRIBER_PATH.INSIGHTS_CHANNELS, name: 'Channels', component: Channels },

];

reportRoutes.push(
     {
          path: SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES,
          name: 'Revealed Companies',
          component: EventExplorer,
          props: { title: 'Revealed Companies Details', reportName: REPORT_NAMES.COMPANY_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PEOPLE_COUNT,
          name: 'People count',
          component: EventExplorer,
          props: { title: 'People and User Count Details', reportName: REPORT_NAMES.PEOPLE_AND_USER_COUNT_REPORT, reportType: REPORT_TYPE.ENGAGEMENT },
     },
);

const newNavigationRoutes = [
     // ONEView
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_PERFORMANCE_OVERVIEW,
          name: 'Performance Overview',
          component: Dashboard,
          props: { title: 'Performance Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ROI_OVERVIEW,
          name: 'ROI Overview',
          component: InsightsComingSoon,
          props: { title: 'ROI Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_B2B_MARKETING_INFLUENCE,
          name: 'Marketing Influence',
          component: InsightsComingSoon,
          props: { title: 'Marketing Influence Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ECOMMERCE_OVERVIEW,
          name: 'eCommerce Overview',
          component: EcommerceOverview,
          props: { title: 'eCommerce Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_FINANCELINK_OVERVIEW,
          name: 'FinanceLink Overview',
          component: InsightsComingSoon,
          props: { title: 'FinanceLink Overview Details', reportName: REPORT_NAMES.CONSENT_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_HEALTHLINK_OVERVIEW,
          name: 'HealthLink Overview',
          component: InsightsComingSoon,
          props: { title: 'HealthLink Overview Details', reportName: REPORT_NAMES.GEOGRAPHY_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_FUNNELS,
          name: 'Funnels',
          component: InsightsComingSoon,
          props: { title: 'Funnels Details', reportName: REPORT_NAMES.DEVICE_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_PIPELINES_ACTIVITY,
          name: 'Activity',
          component: InsightsComingSoon,
          props: { title: 'Activity Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_PIPELINES_SNAPSHOT,
          name: 'Snapshot',
          component: InsightsComingSoon,
          props: { title: 'Snapshot Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_PIPELINES_FORECAST,
          name: 'Forecast',
          component: InsightsComingSoon,
          props: { title: 'Forecast Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_OVERVIEW,
          name: 'Conversions Overview',
          component: InsightsComingSoon,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_PACING,
          name: 'Pacing',
          component: InsightsComingSoon,
          props: { title: 'Pacing Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_TREND,
          name: 'Trend',
          component: InsightsComingSoon,
          props: { title: 'Trend Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_CONVERSIONS_ACTION,
          name: 'By Conversion Action',
          component: InsightsComingSoon,
          props: { title: 'By Conversion Action Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_OVERVIEW,
          name: 'Attribution Overview',
          component: InsightsComingSoon,
          props: { title: 'Attribution Overview Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_CHANNEL,
          name: 'Channel',
          component: InsightsComingSoon,
          props: { title: 'Channel Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_ENGAGEMENT,
          name: 'Content Engagement',
          component: InsightsComingSoon,
          props: { title: 'Content Engagement Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_PATH,
          name: 'Path',
          component: InsightsComingSoon,
          props: { title: 'Path Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_INTENT,
          name: 'Intent & Content Type',
          component: InsightsComingSoon,
          props: { title: 'Intent & Content Type Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.ONEVIEW_ATTRIBUTION_SELF_REPORTED,
          name: 'Self Reported',
          component: InsightsComingSoon,
          props: { title: 'Self Reported Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },

     // Performance - Overview
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_OVERVIEW,
          name: 'Overview',
          component: PerformanceOverview,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // Performance - Attributes
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_CONSENT,
          name: 'Consent',
          component: EventExplorer,
          props: { title: 'Consent Details', reportName: REPORT_NAMES.CONSENT_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_GEOGRAPHY,
          name: 'Geography',
          component: EventExplorer,
          props: { title: 'Geography Details', reportName: REPORT_NAMES.GEOGRAPHY_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_DEVICES,
          name: 'Devices',
          component: EventExplorer,
          props: { title: 'Device Details', reportName: REPORT_NAMES.DEVICE_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ATTRIBUTES_BROWSERS,
          name: 'Browsers',
          component: EventExplorer,
          props: { title: 'Browser Details', reportName: REPORT_NAMES.BROWSER_REPORT, reportType: REPORT_TYPE.ATTRIBUTES },
     },
     // Performance - Acquisition
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_CHANNELS,
          name: 'Channels',
          component: EventExplorer,
          props: { title: 'Channel Details', reportName: REPORT_NAMES.CHANNEL_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_SOURCES,
          name: 'Source',
          component: EventExplorer,
          props: { title: 'Source Details', reportName: REPORT_NAMES.SOURCE_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_UTMS,
          name: 'UTMs',
          component: EventExplorer,
          props: { title: 'UTMs Details', reportName: REPORT_NAMES.UTM_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ACQUISITION_REFERRALS,
          name: 'Referrals',
          component: EventExplorer,
          props: { title: 'Referrals Details', reportName: REPORT_NAMES.REFERRALS_REPORT, reportType: REPORT_TYPE.ACQUISITION },
     },
     // Performance - Pacing
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_PACING,
          name: 'Pacing',
          component: InsightsComingSoon,
          props: { title: 'Pacing Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     // Performance - Trend
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_TREND,
          name: 'Trend',
          component: InsightsComingSoon,
          props: { title: 'Trend Details', reportName: REPORT_NAMES.BROWSER_REPORT },
     },
     // Performance - Engagement
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ENGAGEMENT_OVERVIEW,
          name: 'Overview',
          component: WebEngagementOverview,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ENGAGEMENT_PROPERTY,
          name: 'By Property',
          component: InsightsComingSoon,
          props: { title: 'By Property Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // Performance - Content
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_ALL_PAGES,
          name: 'All Pages',
          component: EventExplorer,
          props: { title: 'All Pages', reportName: REPORT_NAMES.ALL_PAGE_REPORT, reportType: REPORT_TYPE.CONTENT },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_ENTRANCE,
          name: 'Entrance And Exit Pages',
          component: EventExplorer,
          props: { title: 'Entrance And Exit Pages', reportName: REPORT_NAMES.ENTRANCE_AND_EXIT_REPORT, reportType: REPORT_TYPE.CONTENT },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_VISIBLE,
          name: 'Visible Time',
          component: InsightsComingSoon,
          props: { title: 'Visible Time Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_CONTENT_PAGE_FLOW,
          name: 'Page Flow',
          component: InsightsComingSoon,
          props: { title: 'Page Flow Detail', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // Performance - Web Conversions
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_OVERVIEW,
          name: 'Overview',
          component: WebConversionsOverview,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_ACTION,
          name: 'By Conversion Action',
          component: InsightsComingSoon,
          props: { title: 'By Conversion Action Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_PROPERTY,
          name: 'By Property',
          component: InsightsComingSoon,
          props: { title: 'By Property Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_WEB_ATTRIBUTION,
          name: 'Attribution',
          component: EventExplorer,
          props: { title: 'Attribution Details', reportName: REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT, reportType: REPORT_TYPE.CONVERSIONS },
     },
     // Performance - eCommerce
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_PURCHASE_FLOW,
          name: 'Purchase Flow',
          component: EventExplorer,
          props: { title: 'Purchase Flow', reportName: REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW, reportType: REPORT_TYPE.ECOMMERCE },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_ITEMS,
          name: 'Items',
          component: EventExplorer,
          props: { title: 'Items', reportName: REPORT_NAMES.ECOMMERCE_ITEMS, reportType: REPORT_TYPE.ECOMMERCE },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_PURCHASE_ACTIVITY,
          name: 'Purchase Activity',
          component: EventExplorer,
          props: { title: 'Purchase Activity', reportName: REPORT_NAMES.ECOMMERCE_PURCHASE_ACTIVITY, reportType: REPORT_TYPE.ECOMMERCE },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_PURCHASE_TRANSACTIONS,
          name: 'Purchase Transaction',
          component: EventExplorer,
          props: { title: 'Purchase Transaction', reportName: REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION, reportType: REPORT_TYPE.ECOMMERCE },
     },
     // Performance - Sales Objects
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_REPORTING,
          name: 'Reporting',
          component: InsightsComingSoon,
          props: { title: 'Reporting Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // Performance - Sales Conversions
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_OVERVIEW,
          name: 'Overview',
          component: InsightsComingSoon,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_ACTION,
          name: 'By Conversion Action',
          component: InsightsComingSoon,
          props: { title: 'By Conversion Action Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_PROPERTY,
          name: 'By Property',
          component: InsightsComingSoon,
          props: { title: 'By Property Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_SALES_ATTRIBUTION,
          name: 'Attribution',
          component: InsightsComingSoon,
          props: { title: 'Attribution Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // Performance - Paid
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_PAID,
          name: 'Paid Performance',
          component: InsightsComingSoon,
          props: { title: 'Paid Performance Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // Performance - ABM
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_COMPANIES_OVERVIEW,
          name: 'Companies Overview',
          component: InsightsComingSoon,
          props: { title: 'Companies Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_TARGET_ACCOUNT,
          name: 'Target Account Overview',
          component: InsightsComingSoon,
          props: { title: 'Target Account Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_COMPANIES_PROPERTY,
          name: 'Companies by Property',
          component: InsightsComingSoon,
          props: { title: 'Companies by Property Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_EXPLORE_COMPANIES,
          name: 'Explore Companies',
          component: InsightsComingSoon,
          props: { title: 'Explore Companies Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.PERFORMANCE_BUYING_GROUP,
          name: 'Buying Group',
          component: InsightsComingSoon,
          props: { title: 'Buying Group Details', reportName: REPORT_NAMES.USER_DETAILS },
     },

     // Journeys
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_EVENTS,
          name: 'Events',
          component: EventExplorer,
          props: { title: 'Event Details', reportName: REPORT_NAMES.EVENT_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_CONVERSION_EVENTS,
          name: 'Conversion Events',
          component: EventExplorer,
          props: { title: 'Conversion Events', reportName: REPORT_NAMES.CONVERSION_EVENTS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_SESSIONS,
          name: 'Sessions',
          component: EventExplorer,
          props: { title: 'Session Details', reportName: REPORT_NAMES.SESSION_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_SESSION_REPLAYS,
          name: 'Session Replays',
          component: InsightsComingSoon,
          props: { title: 'Session Replays', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_USERS,
          name: 'Users',
          component: EventExplorer,
          props: { title: 'User Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_PEOPLE,
          name: 'People',
          component: EventExplorer,
          props: { title: 'People Details', reportName: REPORT_NAMES.PERSON_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_REVEAL_COMPANIES,
          name: 'Revealed Companies',
          component: EventExplorer,
          props: { title: 'Revealed Companies Details', reportName: REPORT_NAMES.COMPANY_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_PAGE_VIEW,
          name: 'Page View Explorer',
          component: EventExplorer,
          props: { title: 'Page View Details', reportName: REPORT_NAMES.PAGE_VIEW_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_FORM_EXPLORER,
          name: 'Form Explorer',
          component: EventExplorer,
          props: { title: 'Form Explorer', reportName: REPORT_NAMES.FORM_CATEGORY_REPORT },
     },
     {
          path: NEW_NAVIGATION_PATH.JOURNEY_FORM_SUBMISSION,
          name: 'Form Submission Events',
          component: EventExplorer,
          props: { title: 'Form Submission Events', reportName: REPORT_NAMES.FORM_SUBMISSION_EVENTS },
     },

     // Custom
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_DASHBOARDS,
          name: 'Dashboards',
          component: InsightsComingSoon,
          props: { title: 'Dashboards', reportName: REPORT_NAMES.USER_DETAILS },
     },
     { 
          path: NEW_NAVIGATION_PATH.CUSTOM_SAVE_REPORTS,
          name: 'Save Reports',
          component: SavedReports
     },
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_REPORTS,
          name: 'Custom Reports',
          component: CustomReports,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_REPORT_DETAIL,
          component: CustomReportDetails
     },
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_PACING_TRENDS,
          name: 'Pacing & Trends',
          component: InsightsComingSoon,
          props: { title: 'Pacing & Trends', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_ATTRIBUTION,
          name: 'Attribution',
          component: InsightsComingSoon,
          props: { title: 'Attribution', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_COLLECTIONS,
          name: 'Collections',
          component: InsightsComingSoon,
          props: { title: 'Collections', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.CUSTOM_SCHEDULED,
          name: 'Scheduled',
          component: InsightsComingSoon,
          props: { title: 'Scheduled', reportName: REPORT_NAMES.USER_DETAILS },
     },

     // Data Settings
     {
          path: NEW_NAVIGATION_PATH.DATA_METRICS,
          name: 'Metrics and Dimensions',
          component: MetricsAndDimensions
     },
     {
          path: NEW_NAVIGATION_PATH.DATA_CHANNELS,
          name: 'Channels',
          component: Channels
     },
     {
          path: NEW_NAVIGATION_PATH.DATA_SEGMENTS,
          name: 'Segment',
          component: Segment
     },
     {
          path: NEW_NAVIGATION_PATH.DATA_SETS,
          name: 'Custom Data Sets',
          component: InsightsComingSoon,
          props: { title: 'Custom Data Sets', reportName: REPORT_NAMES.USER_DETAILS },
     },

     // Data Rules
     {
          path: NEW_NAVIGATION_PATH.RULES_DATA_LAYER_VALUES,
          name: 'Custom Data Layer Variables',
          component: CustomDataLayerVariables,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.RULES_BROWSER_STORAGE,
          name: 'Custom Browser Storage Variables',
          component: CustomBrowserStorageVariables,
          exact: true,
     },
     {
          path: NEW_NAVIGATION_PATH.RULES_POPULATE_FORM_FIELDS,
          name: 'Populate Form Fields',
          component: PopulateFormFields,
          props: { title: 'Populate Form Fields', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: NEW_NAVIGATION_PATH.RULES_DATA_TRANSFORMATIONS,
          name: 'Other Data Transformations',
          component: DataTransformations,
          props: { title: 'Other Data Transformations', reportName: REPORT_NAMES.USER_DETAILS },
     },

     // Monitoring
     {
          path: NEW_NAVIGATION_PATH.MONITORING_DASHBOARD,
          name: 'Monitoring Dashboard',
          component: MonitoringComingSoon,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_NOTIFICATIONS,
          name: 'Monitoring Notifications',
          component: MonitoringNotificationsV2,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_NOTIFICATIONS_DETAILS,
          name: 'Monitoring Notification Details',
          component: MonitoringNotificationDetailV2
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_CONVERSIONS,
          name: 'Automatic Monitoring Conversasions',
          component: MonitoringConversions
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_LISTENERS,
          name: 'Automatic Monitoring Listeners',
          component: MonitoringListeners
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_DESTINATIONS,
          name: 'Automatic Monitoring Destinations',
          component: MonitoringDestinations
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_AUTOMATIC_FORM_CATEGORIES,
          name: 'Automatic Monitoring Form Categories',
          component: MonitoringFormCategories
     },
     {
          path: NEW_NAVIGATION_PATH.MONITORING_CUSTOM_MONITORING,
          name: 'Custom Monitoring Rules',
          component: CustomMonitoringRules,
          exact: true
     },

     // Consent
     {
          path: NEW_NAVIGATION_PATH.CONSENT_SETTING,
          name: 'Consent Rules',
          component: ConsentRules,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.CONSENT_DATA_CATEGORIES,
          name: 'Data Categories',
          component: DataCategories,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.CONSENT_LOGS,
          name: 'Consent Logs',
          component: ConsenLogs,
          exact: true
     },
     {
          path: NEW_NAVIGATION_PATH.CONSENT_DATA_REQUESTS,
          name: 'Data Requests',
          component: DataDeletionRequest,
          exact: true
     },

     // Reveal
     {
          path: NEW_NAVIGATION_PATH.REVEAL_PEOPLE,
          name: 'Revealed People',
          component: RevealPeople
     },
     {
          path: NEW_NAVIGATION_PATH.REVEAL_COMPANIES,
          name: 'Revealed Companies',
          component: RevealedCompanies
     },
]

const defaultSubscriberRoutes = [
     { path: SUBSCRIBER_PATH.USERS, name: 'Users', component: Users },
     { path: SUBSCRIBER_PATH.VERSIONS, name: 'Versions', component: Versions },
     { path: SUBSCRIBER_PATH.ACCOUNT_SETTINGS, name: 'Account Settings', component: AccountSettings },
     { path: SUBSCRIBER_PATH.WEBSITE, name: 'Website', component: Website },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.PROFILE, name: 'Profile', component: Profile },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.EMAIL_NOTIFICATIONS, name: 'Email Notifications', component: EmailNotifications, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.AFFILIATE, name: 'Affiliate', component: Affiliate },
     { path: SUBSCRIBER_PATH.PLAN_USAGE, name: 'Plan Usage', component: PlanUsage },
     { path: SUBSCRIBER_PATH.BILLING_INVOICES, name: 'Billing Invoices', component: BillingInvoices },
     { path: SUBSCRIBER_PATH.DATA_LAYER_VARIABLES, name: 'Data Layer Variables', component: DataLayerVariables },
     { path: SUBSCRIBER_PATH.BROWSER_STORAGE_VARIABLES, name: 'Browser Storage Variables', component: BrowserStorageVariables },
     { path: SUBSCRIBER_PATH.MONITORING_DASHBOARD, name: 'Monitoring Dashboard', component: MonitoringComingSoon, exact: true },
     { path: SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS_V2, name: 'Monitoring Notifications', component: MonitoringNotificationsV2, exact: true },
     { path: SUBSCRIBER_PATH.MONITORING_NOTIFICATION_DETAILS_V2, name: 'Monitoring Notification Details', component: MonitoringNotificationDetailV2 },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS, name: 'Support Tickets', component: Tickets, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS_WITH_PARAMS_ID, name: 'Details', component: TicketDetails },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.MY_ACCOUNTS, name: 'My Accounts', component: MyAccounts, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS, name: 'Masterclass', component: Masterclass, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.LESSON, name: 'Lesson', component: Lesson },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.CERTIFICATION, name: 'Certification', component: Certification },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.OFFICE_HOURS, name: 'Office Hours', component: OfficeHours },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.BECOME_A_PARTNER, name: 'Become a Partner', component: BecomeAPartner },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.PAYMENT_METHODS, name: 'Payment Methods', component: PaymentMethods },
     { path: SUBSCRIBER_PATH.CONSENT_LISTENER, name: 'Consent Listener', component: ConsentListener },
     { path: SUBSCRIBER_PATH.TRIGGER_AND_CONVERSIONS, name: 'Triggers & Conversions', component: TriggersAndConversions, exact: true },
     { path: SUBSCRIBER_PATH.TRIGGER, name: 'Trigger', component: TriggersAndConversions, exact: true, notShow: true },
     { path: SUBSCRIBER_PATH.CONVERSION, name: 'Conversion', component: TriggersAndConversions, exact: true, notShow: true },
     { path: SUBSCRIBER_PATH.CUSTOM_DATA_LAYER_VARIABLES, name: 'Custom Data Layer Variables', component: CustomDataLayerVariables, exact: true },
     {
          path: SUBSCRIBER_PATH.CUSTOM_BROWSER_STORAGE_VARIABLES,
          name: 'Custom Browser Storage Variables',
          component: CustomBrowserStorageVariables,
          exact: true,
     },
     {
          path: SUBSCRIBER_PATH.CUSTOM_FORM_CATEGORIES_VARIABLES,
          name: 'Custom Form Categories Variables',
          component: CustomFormCategories,
          exact: true,
     },
     { path: SUBSCRIBER_PATH.CUSTOM_MONITORING_RULES, name: 'Custom Monitoring Rules', component: CustomMonitoringRules, exact: true },
     { path: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_CONVERSIONS, name: 'Automatic Monitoring Conversasions', component: MonitoringConversions },
     { path: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_LISTENERS, name: 'Automatic Monitoring Listeners', component: MonitoringListeners },
     { path: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_DESTINATIONS, name: 'Automatic Monitoring Destinations', component: MonitoringDestinations },

     { path: SUBSCRIBER_PATH.CONSENT_RULES, name: 'Consent Rules', component: ConsentRules, exact: true },
     { path: SUBSCRIBER_PATH.PERSONAL_DATA, name: 'Personal Data', component: PersonalData, exact: true },
     { path: SUBSCRIBER_PATH.DATA_REQUESTS, name: 'Data Requests', component: DataDeletionRequest, exact: true },
     { path: SUBSCRIBER_PATH.INSIGHTS_DATA_LAYER, name: 'Overview', component: InsightsDataLayer, notShow: true },
     { path: SUBSCRIBER_PATH.INSIGHTS_DATA_LAYER, name: 'Overview', component: InsightsComingSoon, notShow: true },
     // Data settings
     // { path: SUBSCRIBER_PATH.INSIGHTS_METRICS_AND_DIMENSIONS, name: 'Metrics & Dimensions', component: InsightsComingSoon, props: { title: 'Metrics & Dimensions', reportName: REPORT_NAMES.USER_DETAILS } },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_DATA_SETS,
          name: 'Custom Data Sets',
          component: InsightsComingSoon,
          props: { title: 'Custom Data Sets', reportName: REPORT_NAMES.USER_DETAILS },
     },
     { path: SUBSCRIBER_PATH.CONSENT_LOGS, name: 'Consent Logs', component: ConsenLogs, exact: true },
     { path: SUBSCRIBER_PATH.DATA_CATEGORIES, name: 'Data Categories', component: DataCategories, exact: true },
     ...reportRoutes,
     // { path: SUBSCRIBER_PATH.INSIGHTS_PEOPLE_COUNT, name: 'People count', component: InsightsComingSoon, props: { title: 'People count Details', reportName: REPORT_NAMES.USER_DETAILS } },
     //Content
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SCROLL_DEPTH,
          name: 'Scroll Depth',
          component: InsightsComingSoon,
          props: { title: 'Scroll Depth Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_VISIBLE_TIME,
          name: 'Visible Time',
          component: InsightsComingSoon,
          props: { title: 'Visible Time Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PAGE_FLOW,
          name: 'Page Flow',
          component: InsightsComingSoon,
          props: { title: 'Page Flow Detail', reportName: REPORT_NAMES.USER_DETAILS },
     },
     //Conversions
     {
          path: SUBSCRIBER_PATH.INSIGHTS_BY_PAGE_JOURNEY,
          name: 'By page Journey',
          component: InsightsComingSoon,
          props: { title: 'By page Journey Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ATTRIBUTION,
          name: 'Attribution',
          component: InsightsComingSoon,
          props: { title: 'Attribution Details', reportName: REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT },
     },
     //eCommerce
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_OVERVIEW,
          name: 'Overview',
          component: InsightsComingSoon,
          props: { title: 'Overview Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_TRANSACTIONS,
          name: 'Transactions',
          component: InsightsComingSoon,
          props: { title: 'Transactions Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_SOURCE,
          name: 'By Source eCommerce',
          component: InsightsComingSoon,
          props: { title: 'By Source eCommerce Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_UTM,
          name: 'By UTM eCommerce',
          component: InsightsComingSoon,
          props: { title: 'By UTM Details eCommerce', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PRODUCT_PERFORMANCE,
          name: 'Product Performance',
          component: InsightsComingSoon,
          props: { title: 'Product Performance Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_PURCHASE_FUNNEL,
          name: 'Purcase Funnel',
          component: InsightsComingSoon,
          props: { title: 'Purcase Funnel Details', reportName: REPORT_NAMES.USER_DETAILS },
     },
     //Explore Data
     {
          path: SUBSCRIBER_PATH.INSIGHTS_SESSION_REPLAYS,
          name: 'Session Replays',
          component: InsightsComingSoon,
          props: { title: 'Session Replays', reportName: REPORT_NAMES.USER_DETAILS },
     },
     // { path: SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES, name: 'Revealed Companies', component: InsightsComingSoon, props: { title: 'Revealed Companies Details', reportName: REPORT_NAMES.USER_DETAILS } },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_FORM_CATEGORY,
          name: 'Form Details',
          component: InsightsComingSoon,
          props: { title: 'Form Details', reportName: REPORT_NAMES.FORM_CATEGORY_REPORT },
     },
     {
          path: SUBSCRIBER_PATH.INSIGHTS_FORM_CATEGORY,
          name: 'Form Category',
          component: InsightsComingSoon,
          props: { title: 'Form Category', reportName: REPORT_NAMES.FORM_CATEGORY_REPORT },
     },
     { path: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORTS, name: 'Custom Reports', component: InsightsComingSoon },
     //GOALS
     // {
     //      path: SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS,
     //      name: 'Web Conversions',
     //      component: GoalsLayout,
     //      props: {
     //           title: 'Web Conversions',
     //           description:
     //                'Centralize how you define conversions on your website, such as views of critical pages, specific types of form submissions, chat activity and more. Conversions are based on rules that match inside your data layer activity and can be easily distributed to any destination you use.',
     //      },
     // },
     // {
     //      path: SUBSCRIBER_PATH.GOALS_ECOMMERCE,
     //      name: 'eCommerce',
     //      component: GoalsLayout,
     //      props: {
     //           title: 'eCommerce Events',
     //           description:
     //                "ListenLayer automatically generates structured eCommerce data layer activity when you enable an eCommerce Listener. The events below are made available as pre-built data actions within your Destinations so you can easily distribute them. They also power eCommerce reporting insight ListenLayer's Insights Engine. ",
     //      },
     // },
     // {
     //      path: SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS,
     //      name: 'Web Triggers',
     //      component: GoalsLayout,
     //      props: {
     //           title: 'Triggers',
     //           description:
     //                'Write rules to define triggers based on acitivity inside the data layer. Triggers can be used to send specific data to destinations. We automatically create commonly used triggers based on Listeners you have enabled.',
     //      },
     // },
     // { path: SUBSCRIBER_PATH.GOALS_WEB_FUNNELS, name: 'Funnels', component: GoalsLayout, props: { title: 'Web Funnels' } },
     // {
     //      path: SUBSCRIBER_PATH.GOALS_FORM_CATEGORIES,
     //      name: 'Form Categories',
     //      component: GoalsLayout,
     //      props: {
     //           title: 'Manage Form Categories',
     //           description:
     //                'ListenLayer automatically finds forms on your website and helps you name then and group them into categories that drive your conversion tracking.',
     //      },
     // },
     // { path: SUBSCRIBER_PATH.GOALS_SALES_PIPELINE, name: 'Sales Pipeline', component: GoalsLayout, props: { title: 'Sales Pipeline' } },
     // { path: SUBSCRIBER_PATH.GOALS_COHORTS, name: 'Cohorts', component: GoalsLayout, props: { title: 'Cohorts' } },
     // { path: SUBSCRIBER_PATH.GOALS_TARGET_ACCOUNTS, name: 'Target Accounts', component: GoalsLayout, props: { title: 'Target Accounts' } },
     // { path: SUBSCRIBER_PATH.GOALS_COMPETITORS, name: 'Competitors', component: GoalsLayout, props: { title: 'Competitors' } },

     { path: SUBSCRIBER_PATH.INSIGHTS_REVEAL, name: 'Reveal', component: Reveal },
     { path: SUBSCRIBER_PATH.INSIGHTS_METRICS_AND_DIMENSIONS, name: 'Metrics and Dimensions', component: InsightsComingSoon },
     // { path: SUBSCRIBER_PATH.EXTERNAL_DATA, name: 'External Data', component: ExternalData },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_FROM_FILE, name: 'From File', component: FromFile, exact: true },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_GOOGLE_ADS, name: 'Google Ads', component: GoogleAds, exact: true },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_FACEBOOK_ADS, name: 'Facebook Ads', component: FacebookAds, exact: true },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_SALESFORCE, name: 'Salesforce', component: Salesforce, exact: true },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_TIK_TOK, name: 'Tik Tok', component: TikTok, exact: true },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_MICROSOFT_ADS, name: 'Microsoft Ads', component: MicrosoftAds, exact: true },
     { path: SUBSCRIBER_PATH.EXTERNAL_DATA_LINKEDIN_ADS, name: 'Linkedin Ads', component: LinkedinAds, exact: true },
     { path: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_FORMCATEGORIES, name: 'Automatic Monitoring Form Categories', component: MonitoringFormCategories },

     { path: '/:secondId/dashboard', exact: true, name: 'Overview', component: Dashboard },
     { path: '/:secondId/', exact: true, name: 'Overview', component: InsightsDataLayer },
     // New Navigation
     ...newNavigationRoutes,
];

const subscriberNoAccountRoutes = [
     { path: '/', exact: true, name: 'Dashboard', component: NoAccountDashboard },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.PROFILE, name: 'Profile', component: Profile },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.EMAIL_NOTIFICATIONS, name: 'Email Notifications', component: EmailNotifications },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.AFFILIATE, name: 'Affiliate', component: Affiliate },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS, name: 'Support Tickets', component: Tickets, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS_WITH_PARAMS_ID, name: 'Details', component: TicketDetails },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.MY_ACCOUNTS, name: 'My Accounts', component: MyAccounts, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS, name: 'Masterclass', component: Masterclass, exact: true },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.CERTIFICATION, name: 'Masterclass', component: Certification },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.OFFICE_HOURS, name: 'Office Hours', component: OfficeHours },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.BECOME_A_PARTNER, name: 'Become a Partner', component: BecomeAPartner },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.PAYMENT_METHODS, name: 'Payment Methods', component: PaymentMethods },
     { path: SUBSCRIBER_MY_ACCOUNT_PATH.LESSON, name: 'Lesson', component: Lesson },
];

export { defaultSubscriberRoutes, subscriberNoAccountRoutes };

import axios from 'axios';
import { getTokenCookie } from './utils';
import { compressData } from './utils/compressionUtils';

export const callApi = (endpoint, method = 'GET', data, token) => {
     let headers = null;

     if (token) {
          headers = { Authorization: `Bearer ${token}` };
     }

     let urlApi = process.env.REACT_APP_API;

     let options = {
          url: `${urlApi}/${endpoint}`,
          method,
     };

     if (headers) {
          options.headers = headers;
     }

     if (data !== null) {
          options.data = data;
     }

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callCompressApi = async (endpoint, method = 'GET', data, token) => {
     let headers = null;

     if (token) {
          headers = { Authorization: `Bearer ${token}` };
     }

     headers = {
          ...headers,
          'Content-Encoding': "zstd",
          'Content-Type': "application/binary"
     };

     let urlApi = process.env.REACT_APP_API;

     let options = {
          url: `${urlApi}/${endpoint}`,
          method,
     };

     if (headers) {
          options.headers = headers;
     }

     if (data !== null && typeof data !== 'undefined') {
          const compressed = await compressData(data)
          options.data = compressed;
     }

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

// Call api with a token which is got from cookie
export const callTokenApi = (endpoint, method = 'GET', data, cancelToken) => {
     const token = getTokenCookie();

     if (token) {
          let headers = null;
          headers = { Authorization: `Bearer ${token}` };

          let urlApi = process.env.REACT_APP_API;

          let options = {
               url: `${urlApi}/${endpoint}`,
               method,
          };

          if (headers) {
               options.headers = headers;
          }

          if (data !== null) {
               options.data = data;
          }

          // Add cancel token to axios to abort request if need
          if (cancelToken) {
               options.cancelToken = cancelToken;
          }

          return axios(options)
               .then((response) => response)
               .catch((error) => error.response);
     }
};

export const callCompressTokenApi = async (endpoint, method = 'GET', data, cancelToken) => {
     const token = getTokenCookie();
     if (token) {
          let headers = null;
          headers = {
               Authorization: `Bearer ${token}`,
               'Content-Encoding': "zstd",
               'Content-Type': "application/binary"
          };
          let urlApi = process.env.REACT_APP_API;

          let options = {
               url: `${urlApi}/${endpoint}`,
               method,
          };

          if (headers) {
               options.headers = headers;
          }

          if (data !== null && typeof data !== 'undefined') {
               const compressed = await compressData(data)
               options.data = compressed;
          }

          // Add cancel token to axios to abort request if need
          if (cancelToken) {
               options.cancelToken = cancelToken;
          }

          return axios(options)
               .then((response) => response)
               .catch((error) => error.response);
     }
};

export const callSalesforceApi = (endpoint, method = 'GET', data, cancelToken) => {
     const token = getTokenCookie();
     if (token) {
          let urlApi = process.env.REACT_APP_SALESFORCE_API;

          let headers = {
               Authorization: `Bearer ${token}`,
               'Content-Type': 'application/json',
          };
          let options = {
               url: `${urlApi}/${endpoint}`,
               method,
          };

          if (headers) {
               options.headers = headers;
          }

          if (data !== null) {
               options.data = data;
          }

          // Add cancel token to axios to abort request if need
          if (cancelToken) {
               options.cancelToken = cancelToken;
          }

          return axios(options)
               .then((response) => response)
               .catch((error) => error.response);
     }
};

const cancelTokenSources = {};

export const callTokenApiCancel = async (endpoint, method = 'GET', data, setCancelApi) => {
     const token = getTokenCookie();

     if (token) {
          let headers = null;
          headers = { Authorization: `Bearer ${token}` };

          let urlApi = process.env.REACT_APP_API;

          let options = {
               url: `${urlApi}/${endpoint}`,
               method,
          };

          if (headers) {
               options.headers = headers;
          }

          if (data !== null) {
               options.data = data;
          }

          if (cancelTokenSources[endpoint]) {
               cancelTokenSources[endpoint].cancel('Request cancelled due to a new request.');
          }

          cancelTokenSources[endpoint] = axios.CancelToken.source();
          options.cancelToken = cancelTokenSources[endpoint].token;
          if (setCancelApi) {
               setCancelApi(cancelTokenSources);
          }

          try {
               const response = await axios(options);
               return response;
          } catch (error) {
               if (axios.isCancel(error)) {
                    console.log('Request was cancelled:', error.message);
               } else {
                    console.error('API error:', error);
                    return error.response;
               }
               return error.response;
          }
     }
};

export const callCompressTokenApiCancel = async (endpoint, method = 'GET', data, setCancelApi) => {
     const token = getTokenCookie();

     if (token) {
          let headers = null;
          headers = {
               Authorization: `Bearer ${token}`,
               'Content-Encoding': "zstd",
               'Content-Type': "application/binary"
          };

          let urlApi = process.env.REACT_APP_API;

          let options = {
               url: `${urlApi}/${endpoint}`,
               method,
          };

          if (headers) {
               options.headers = headers;
          }

          if (data !== null && typeof data !== 'undefined') {
               const compressed = await compressData(data)
               options.data = compressed;
          }

          if (cancelTokenSources[endpoint]) {
               cancelTokenSources[endpoint].cancel('Request cancelled due to a new request.');
          }

          cancelTokenSources[endpoint] = axios.CancelToken.source();
          options.cancelToken = cancelTokenSources[endpoint].token;
          if (setCancelApi) {
               setCancelApi(cancelTokenSources);
          }

          try {
               const response = await axios(options);
               return response;
          } catch (error) {
               if (axios.isCancel(error)) {
                    console.log('Request was cancelled:', error.message);
               } else {
                    console.error('API error:', error);
                    return error.response;
               }
               return error.response;
          }
     }
};

export const callApiPreviewKV = (data) => {
     let urlApi = process.env.REACT_APP_PREVIEW_KV;

     let options = {
          url: `${urlApi}`,
          method: 'POST',
     };

     if (data !== null) {
          options.data = data;
     }

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callDomainValidation = (data) => {
     let urlApi = process.env.REACT_APP_API_DOMAIN_VALIDATION;

     let options = {
          url: urlApi,
          method: 'POST',
          data,
     };

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callApiPublishVersionLambda = (data) => {
     let urlApi = process.env.REACT_APP_API_PUBLISH_VERSION;

     let options = {
          url: urlApi,
          method: 'POST',
          data,
     };

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callApiGeneratePdf = (endpoint) => {
     let urlApi = process.env.REACT_APP_API;

     let options = {
          url: `${urlApi}/${endpoint}`,
          method: 'GET',
     };

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const makeRequest = (url, method, data, token) => {
     let headers = null;

     if (token) {
          headers = { Authorization: `Bearer ${token}` };
     }

     let options = {
          url,
          method,
     };

     if (headers) {
          options.headers = headers;
     }

     if (data !== null) {
          options.data = data;
     }

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callApiCrawlerStart = (data, isRunningV2) => {
     let urlApi = isRunningV2 ? process.env.REACT_APP_CRAWLER_START : process.env.REACT_APP_CRAWLER_START_V1;

     let options = {
          url: `${urlApi}`,
          method: 'POST',
     };

     if (data !== null) {
          options.data = data;
     }

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callApiCrawlPause = (data) => {
     let urlApi = process.env.REACT_APP_CRAWL_PAUSE;

     let options = {
          url: `${urlApi}`,
          method: 'POST',
     };

     if (data !== null) {
          options.data = data;
     }

     return axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};

export const callApiCheckDomainCompany = async (data) => {
     let urlApi = process.env.REACT_APP_CHECK_COMPANY_DOMAIN || 'https://n4qalm5ada.execute-api.us-east-1.amazonaws.com/dev/check-domain-company';

     let options = {
          url: `${urlApi}`,
          method: 'POST',
     };

     if (data !== null) {
          options.data = data;
     }

     return await axios(options)
          .then((response) => response)
          .catch((error) => error.response);
};
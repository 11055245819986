import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRangeReports, setListSegmentReport } from '../../../../actions/subscriber';
import { callTokenApi } from '../../../../apiCaller';
import { API_CLIENT_GET_LIST_SEGMENT_REPORT, API_CLIENT_REPORTING_CONVERSION_NAME, BQ_JOB_STATUS, DEFAULT_DATE_FORMAT, REPORT_TYPE, SESSION_STORAGE_REPORT_DATE_RANGE } from '../../../../constants';
import { toastError } from '../../../../utils';
import { handleGetSortSegment } from './EventExplorer';

const useFirstFetchReportData = (props) => {
     const {
          isConversionNameRate,
          isConversionName,
          isConversionAllReport,
          setConversionName,
          setConversionNameMetric,
          reportName,
          setSegmentSticky,
          setSort,
          // metrics,
          filterMetric,
          dataParamValue,
          recordProfile,
          dateRangeConfig,
          setDateRangeConfig,
          dateScopes,
          defaultDateRange,
          setListSegmentReportApply,
          setListChartSegmentApply,
          setDefaultConfig,
          reportViewParam,
          reportType,
     } = props;
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const user = useSelector((state) => state.theme.user);
     const dateRangeReportNoSticky = useSelector((state) => state.subscriber.dateRangeReportNoSticky);
     const { segmentId: paramSegmentId } = dataParamValue || {};
     const bodyData = {
          accountId: activeAccount.id,
          isConversionName,
          isConversionNameRate,
          isConversionAllReport,
          reportName,
     };

     const isSaveReport = reportViewParam
     const [conversionNameLoading, setConversionNameLoading] = useState(true);

     useEffect(() => {
          const fetchData = async () => {
               if (!recordProfile) {
                    await callTokenApi(
                         API_CLIENT_GET_LIST_SEGMENT_REPORT.replace(':accountId', activeAccount.id) + `?reportName=${reportName}&userId=${user.id}`,
                         'GET',
                         null
                    ).then((response) => {
                         if (response.status === 200) {
                              const { segmentReport, segmentSticky, defaultConfig, segmentChart } = response.data;

                              if (Array.isArray(segmentReport)) {
                                   if (typeof paramSegmentId !== 'undefined') {
                                        const newSegmentReport = segmentReport.map((item) => ({ ...item, apply: item.id === paramSegmentId }));
                                        const newListSegmentReportApply = newSegmentReport.filter((item) => item.id === paramSegmentId);

                                        dispatch(setListSegmentReport(newSegmentReport));
                                        setSort(
                                             handleGetSortSegment({
                                                  listSegmentReportApply: newListSegmentReportApply.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE),
                                                  filterMetric,
                                             })
                                        );
                                        setListSegmentReportApply(newListSegmentReportApply);
                                   } else {
                                        dispatch(setListSegmentReport(segmentReport));
                                        setSort(
                                             handleGetSortSegment({
                                                  listSegmentReportApply: segmentReport.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE),
                                                  filterMetric,
                                             })
                                        );
                                        setListSegmentReportApply(segmentReport.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE).sort((a, b) => {
                                             if (a.isCustom !== b.isCustom) {
                                                  return a.isCustom ? 1 : -1;
                                             }
                                             return new Date(a.orderBy) - new Date(b.orderBy)
                                        }));
                                   }
                              }

                              setListChartSegmentApply(segmentChart)
                              setSegmentSticky(segmentSticky);
                              setDefaultConfig((prev) => ({ ...prev, segmentSticky }));

                              if (!isEmpty(defaultConfig) && !dataParamValue) {
                                   const { dateRangeConfig: dateRangeDefault } = defaultConfig;
                                   if (dateRangeDefault && dateRangeDefault.sticky && dateRangeDefault.selection) {
                                        const { compare, comparePeriod, datePreset, dateScope, periodType, selection } = dateRangeDefault;
                                        const newDefaultDateRange = {
                                             selection: {
                                                  startDate: dayjs(selection.startDate),
                                                  endDate: dayjs(selection.endDate),
                                             },
                                        };

                                        if (comparePeriod && compare) {
                                             newDefaultDateRange.compare = {
                                                  startDate: dayjs(compare.startDate),
                                                  endDate: dayjs(compare.endDate),
                                             };
                                        }

                                        let defaultDateRangeConfig = {
                                             comparePeriod,
                                             periodType,
                                             datePreset,
                                        }

                                        if (dateScopes.includes(dateScope)) {
                                             defaultDateRangeConfig.dateScope = dateScope
                                        }

                                        setDateRangeConfig({
                                             ...dateRangeConfig,
                                             ...defaultDateRangeConfig,
                                             sticky: defaultConfig.dateRangeConfig.sticky

                                        });

                                        if (!isSaveReport) {
                                             localStorage.setItem(SESSION_STORAGE_REPORT_DATE_RANGE, JSON.stringify({
                                                  ...dateRangeConfig,
                                                  ...defaultDateRangeConfig,
                                                  ...newDefaultDateRange
                                             }));
                                        }

                                        dispatch(setDateRangeReports(newDefaultDateRange));
                                   } else {
                                        setDateRangeConfig({
                                             ...dateRangeConfig,
                                             sticky: defaultConfig.dateRangeConfig.sticky

                                        });
                                        if (isEmpty(dateRangeReportNoSticky)) {
                                             dispatch(setDateRangeReports(defaultDateRange));
                                        } else {
                                             dispatch(setDateRangeReports(dateRangeReportNoSticky));
                                        }

                                        if (!isSaveReport) {
                                             const { selection } = defaultDateRange
                                             const newDefaultDateRange = {
                                                  selection: {
                                                       startDate: dayjs(selection.startDate).format(DEFAULT_DATE_FORMAT),
                                                       endDate: dayjs(selection.endDate).format(DEFAULT_DATE_FORMAT),
                                                  },
                                             }
                                             localStorage.setItem(SESSION_STORAGE_REPORT_DATE_RANGE, JSON.stringify({
                                                  ...dateRangeConfig,
                                                  ...newDefaultDateRange
                                             }));
                                        }

                                   }

                                   setDefaultConfig((prev) => {
                                        return { ...prev, dateRangeSticky: dateRangeDefault && dateRangeDefault.sticky }
                                   })

                              }

                         } else {
                              toastError(response);
                         }
                    });
               }


               if (isConversionName) {
                    let conversionNameMetric = [];
                    const conversionNameRateMetric = [];
                    const conversionName = [];
                    const conversionNameValue = [];
                    await callTokenApi(API_CLIENT_REPORTING_CONVERSION_NAME, 'POST', bodyData)
                         .then((response) => {
                              if (response && response.status === 200) {
                                   const { conversionNameAll = [] } = response.data
                                   const conversionMetric = response.data.conversionName;
                                   const { eCommerceName } = response.data;
                                   const conversionValueMetric = response.data.conversionNameValue;

                                   conversionMetric.forEach((item) => {
                                        conversionNameMetric.push({ key: item.value, label: item.label, type: item.type, name: item.name, description: item.description });
                                        conversionName.push({ label: item.label, value: item.value, convId: item.convId, type: item.type });
                                   });

                                   conversionValueMetric.forEach((item) => {
                                        conversionNameMetric.push({ key: item.value, label: item.label, convId: item.convId, type: item.type, name: item.name, description: item.description })
                                        // conversionName.push({ label: item.label, value: item.value, convId: item.convId, type: item.type })
                                   });

                                   if (reportType !== REPORT_TYPE.CONVERSIONS && reportType !== REPORT_TYPE.SALES_CONVERSION) {

                                        eCommerceName.forEach((item) => {
                                             conversionNameMetric.push({ key: item.value, label: item.label, type: item.type });
                                             conversionName.push({ label: item.label, value: item.value, type: item.type });
                                        });
                                   }

                                   if (isConversionNameRate) {
                                        const conversionRateMetric = response.data.conversionNameRate;
                                        conversionRateMetric.forEach((item) => {
                                             conversionNameRateMetric.push({ key: item.value, label: item.label, type: item.type, name: item.name, description: item.description });
                                        });
                                   }

                                   conversionNameMetric = [...conversionNameMetric, ...conversionNameRateMetric, ...conversionNameValue];

                                   if (isConversionAllReport) {
                                        conversionNameAll.forEach((item) => {
                                             conversionNameMetric.push({ key: item.value, label: item.label, convId: item.convId, type: item.type, name: item.name, description: item.description });
                                        });
                                   }
                              }
                         })
                         .finally(() => {
                              setConversionNameMetric(conversionNameMetric);
                              setConversionName(conversionName);
                              // setFilterMetric(handleGetOption({ option: [...metrics] }));
                              // setFilterConversionName(handleGetOption({ option: [...metrics] }));
                              setConversionNameLoading(false);
                         });
               } else {
                    setConversionNameMetric([]);
                    setConversionName([]);
                    // setFilterConversionName(handleGetOption({ option: [...metrics]}));
                    // setFilterMetric(handleGetOption({ option: [...metrics, ...[]] }));
                    setConversionNameLoading(false);
               }
          };
          fetchData();
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     return conversionNameLoading;
};

export default useFirstFetchReportData;
